import { Component, OnInit, Inject } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Helper } from 'app/shared/helper';
import { MatDialogRef, MAT_DIALOG_DATA, MatAutocompleteSelectedEvent } from '@angular/material';
import { Userexternal, Project } from '../model/userexternal';
import { UserexternalService } from '../userexternal.service';
import { debounceTime, distinctUntilChanged, tap, switchMap, takeUntil, finalize } from 'rxjs/operators';
import { Profiles } from 'app/shared/auth/auth';
import { DialogEvent } from 'app/shared/component/dialog/common-dialog/common-dialog';
import { ModulespoolService } from 'app/main/modulespool/modulespool.service';
import { Modulespool } from 'app/main/modulespool/model/modulespool';
import { ModulesPoolPracticeTest } from 'app/main/modules-pool/model/modules-pool';
import { AppSettings } from 'app/app.settings';
import { CompetencyPool, SkillIndicatorPool } from 'app/shared/interfaces/references';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-create-certificate',
  templateUrl: './create-certificate.component.html',
  styleUrls: ['./create-certificate.component.scss'],
  animations: fuseAnimations
})
export class CreateCertificateComponent implements OnInit {
  form: FormGroup;
  isEdit = false;
  isProject = false;
  isPractice = false;
  unsubscribeAll = new Subject();
  isLoading = false;
  helper = new Helper();
  filteredModulesPool: Modulespool[] = [];
  filteredEmploye: Profiles[] = [];
  selectedModulesPool = new Modulespool;
  selectedProfile = new Profiles;
  practicalList: ModulesPoolPracticeTest[] = [];
  listEmployee: any[] = [];

  projectTitle = "";
  startDateProject = "";
  endDateProject = "";
  target = "";
  baseline = "";
  learningType: string;
  preTestId: string;
  postTestId: string;
  pre_duration: string;
  post_duration: string;
  projectItem: Project[] = [];
  listDataCertificate: any[] = [];
  private appSettings = new AppSettings();
  apiEndpoint: string;
  competencyPool: CompetencyPool[] = [];
  skillIndicatorPool: SkillIndicatorPool[] = [];

  progressUpload = 0;

  is_project: boolean = false;
  is_post_test: boolean = false;
  is_pre_test: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CreateCertificateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private service: UserexternalService,
    private empModulePoolService: UserexternalService,
    private modulesPoolService: ModulespoolService
  ) {
    this.isEdit = this.data ? true : false;
    this.is_project = this.data.is_project_required == '1' ? true : false;
    this.is_post_test = this.data.post_test ? true : false;
    this.is_pre_test = this.data.pre_test ? true : false;

  }

  ngOnInit() {
    if (this.isEdit) {
      console.log(this.data);
      this.learningType = this.data.learning_type;
      this.selectedModulesPool.pool_name = this.data.module_pool_description;

      this.selectedProfile.nik = this.data.nik;
      this.selectedProfile.fullname = this.data.emp_name;

      let startDate = new Date(this.data.start_date);
      let endDate = new Date(this.data.end_date);

      let score_pre = 0;
      let score_post = 0;
      let score_project = 0;
      let id_certificate = 0;
      let emp_certificate_id = 0;
      if (!this.data.certificate) {
        score_pre = (this.data.pre_test) ? parseFloat(this.data.pre_test.score) : null;
        score_post = (this.data.post_test) ? parseFloat(this.data.post_test.score) : null;
      }

      if (this.data.certificate) {
        id_certificate = this.data.certificate.id;
        emp_certificate_id = this.data.certificate.emp_certificate_id;
        score_pre = this.data.certificate.pre_test_score;
        score_post = this.data.certificate.post_test_score;
        score_project = this.data.certificate.project_score;
      }

      this.form = this.formBuilder.group({
        id: new FormControl(this.data.id),
        id_certificate: new FormControl(id_certificate),
        emp_certificate_id: new FormControl(emp_certificate_id),
        pre_test: new FormControl(score_pre),
        post_test: new FormControl(score_post),
        project_test: new FormControl(score_project),
      });

      if (this.data.is_practice_required == '1') {
        this.isProject = true;
        // this.projectItem = this.data.project;
      }

    }
    else {
      this.form = this.formBuilder.group({
        id: new FormControl(null),
        pre_test: new FormControl(''),
        post_test: new FormControl(''),
        project_test: new FormControl(''),
      });
    }


  }

  onSubmit(): void {
    const payload = {
      // el_module_pool_id: this.form.value.el_module_pool_id,
      // nik: this.form.value.nik,
      // startDate: this.form.value.startDate,
      // endDate: this.form.value.endDate,
      // project: this.projectItem,
      // startDatePractice: this.form.value.startDatePractice,
      // endDatePractice: this.form.value.endDatePractice,
      learningType: this.learningType,
      competencyPool: this.competencyPool,
      skillMatrixPool: this.skillIndicatorPool,
      preTestId: this.preTestId,
      postTestId: this.postTestId,
      pre_duration: this.pre_duration,
      post_duration: this.post_duration,
      // certificate_configurations_id: this.form.value.certificate_configurations_id,
    };

    this.service.createData(payload).subscribe(response => {
      this.dialogRef.close(new DialogEvent('submit', response));
    });

  }

  onUpdate(): void {
    const payload = {
      nik: this.data.nik,
      id: this.form.value.id,
      el_module_pool_id: this.form.value.el_module_pool_id,
      pre_test_score: this.form.value.pre_test,
      post_test_score: this.form.value.post_test,
      project_test_score: this.form.value.project_test,
      certificate_configurations_id: this.data.certificate_configurations_id,
      startDate: this.data.start_date,
      endDate: this.data.end_date,
      id_certificate: this.form.value.id_certificate,
      emp_certificate_id: this.form.value.emp_certificate_id,
    }

    this.service.submitDataCertificate(payload).subscribe(response => {
      this.dialogRef.close(new DialogEvent('submit', response));
    })
  }


}
