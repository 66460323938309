import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { FuseSharedModule } from '@fuse/shared.module';

import { QuickPanelComponent } from 'app/layout/components/quick-panel/quick-panel.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {
    CalendarModule as AngularCalendarModule,
    DateAdapter,
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ColorPickerModule } from 'ngx-color-picker';
import { FuseConfirmDialogModule } from '@fuse/components';
import { MatDividerModule, MatProgressBarModule, MatProgressSpinnerModule, MatSelectModule, MatCheckboxModule } from '@angular/material';
import { DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@NgModule({
    declarations: [
        QuickPanelComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatDividerModule,
        MatListModule,
        MatSlideToggleModule,
        MatButtonModule,
        MatTooltipModule,
        FuseSharedModule,
        FuseSharedModule,
        MatButtonModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatListModule,
        ReactiveFormsModule,
        AngularCalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        ColorPickerModule,
        MatDividerModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatProgressBarModule
    ],
    providers: [DatePipe],
    exports: [
        QuickPanelComponent
    ]
})
export class QuickPanelModule {
}
