import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import {
    FormGroup,
    FormBuilder,
    FormControl,
    Validators
} from "@angular/forms";
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatAutocompleteSelectedEvent,
    MatRadioChange
} from "@angular/material";
import { Subject } from "rxjs";
import {
    takeUntil,
    debounceTime,
    distinctUntilChanged,
    tap,
    switchMap,
    finalize
} from "rxjs/operators";
import {
    ModulesPool,
    POOL_TYPES,
    ModulesPoolPracticeTest
} from "../model/modules-pool";
import { OnlineTestPool } from "app/shared/interfaces/online-test-pool";
import { ModulesPoolService } from "../modules-pool.service";
import { References } from "app/shared/interfaces/references";
import { StorageService } from "app/shared/storage.service";
import { DialogEvent } from "app/shared/component/dialog/common-dialog/common-dialog";

@Component({
    selector: "app-form-modules-pool",
    templateUrl: "./form-modules-pool.component.html",
    styleUrls: ["./form-modules-pool.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class FormModulesPoolComponent implements OnInit {
    form: FormGroup;
    categories: any = [];
    isEdit = false;
    unsubscribeAll = new Subject();
    isLoading = false;
    postTestPool: OnlineTestPool[] = [];
    preTestPool: OnlineTestPool[] = [];
    listCompany: References[];
    company: string;
    poolTypes = POOL_TYPES;
    filteredPostPool: OnlineTestPool[] = [];
    filteredPrePool: OnlineTestPool[] = [];
    isLoadingPost = false;
    isLoadingPre = false;
    practicesPool: ModulesPoolPracticeTest[] = [];
    showPracticeList = false;
    practiceItemName = "";
    errorMsg = "";

    constructor(
        public dialogRef: MatDialogRef<FormModulesPoolComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ModulesPool,
        private formBuilder: FormBuilder,
        private modulesPoolService: ModulesPoolService,
        private storageService: StorageService
    ) {
        this.isEdit = this.data ? true : false;
    }

    ngOnInit() {
        let company = this.storageService.get("empSite");
        if (this.isEdit) {
            company = this.data.bu;
            this.form = this.formBuilder.group({
                pool_name: new FormControl(
                    this.data.pool_name,
                    Validators.required
                ),
                description: new FormControl(
                    this.data.description,
                    Validators.required
                ),
                bu: new FormControl(this.data.bu, Validators.required),
                // country: new FormControl("", Validators.required),
                pool_type: new FormControl(
                    this.data.pool_type,
                    Validators.required
                ),
                post_parameter: new FormControl(
                    this.data.post_parameter,
                    Validators.required
                ),
                post_duration: new FormControl(
                    this.data.post_duration,
                    Validators.maxLength(180)
                ),
                pre_duration: new FormControl(
                    this.data.pre_duration,
                    Validators.maxLength(189)
                ),
                pre_test_pool: new FormControl(this.data.pre_test_pool),
                post_test_pool: new FormControl(this.data.post_test_pool),
                is_practice_required: new FormControl(
                    this.data.is_practice_required,
                    Validators.required
                ),
                is_project_required: new FormControl(
                    this.data.is_project_required,
                    Validators.required
                ),
                is_restriction: new FormControl(
                    this.data.is_restriction,
                    Validators.required
                )
            });
            if (this.data.is_practice_required == "1") {
                this.showPracticeList = true;
                this.practicesPool = this.data.practices_pool;
            }
        } else {
            this.form = this.formBuilder.group({
                pool_name: new FormControl("", Validators.required),
                description: new FormControl("", Validators.required),
                bu: new FormControl(company, Validators.required),
                // country: new FormControl("", Validators.required),
                pool_type: new FormControl("", Validators.required),
                post_parameter: new FormControl("", Validators.required),
                post_duration: new FormControl("", Validators.maxLength(180)),
                pre_duration: new FormControl("", Validators.maxLength(180)),
                pre_test_pool: new FormControl(""),
                post_test_pool: new FormControl(""),
                is_practice_required: new FormControl("0"),
                is_project_required: new FormControl("0"),
                is_restriction: new FormControl("0")
            });
        }
        this.initFilter();
        this.initAutoComplete("pre_test_pool", "PRE");
        this.initAutoComplete("post_test_pool", "POST");
    }

    initFilter(): void {
        this.modulesPoolService.loadCompany().subscribe(val => {
            this.listCompany = val;
        });
    }

    displayFn(questionPool: OnlineTestPool): string {
        if (questionPool) {
            return questionPool.description;
        }
    }

    onSelected(event: MatAutocompleteSelectedEvent, type: string): void {
        if (type === "POST") {
            this.form.get("post_test_pool").setValue(event.option.value);
        } else {
            this.form.get("pre_test_pool").setValue(event.option.value);
        }
    }

    initAutoComplete(field: string, type: string): void {
        let company = this.form.get("bu").value;
        this.form
            .get(field)
            .valueChanges.pipe(
                debounceTime(500),
                distinctUntilChanged(),
                tap(() => {
                    if (type === "POST") {
                        this.isLoadingPost = true;
                    } else {
                        this.isLoadingPre = true;
                    }
                }),
                switchMap(value =>
                    this.modulesPoolService
                        .loadQuestionPool(company, value)
                        .pipe(
                            takeUntil(this.unsubscribeAll),
                            finalize(() => {
                                if (type === "POST") {
                                    this.isLoadingPost = false;
                                } else {
                                    this.isLoadingPre = false;
                                }
                            })
                        )
                )
            )
            .subscribe(data => {
                if (type === "POST") {
                    this.filteredPostPool = data;
                } else {
                    this.filteredPrePool = data;
                }
            });
    }

    onSubmit(): void {
        let form = this.form.value;
        form.practices_pool = this.practicesPool;
        this.modulesPoolService.createData(form).subscribe(
            success => {
                this.dialogRef.close(new DialogEvent("submit", success));
            },
            error => {
                console.log(error);
                this.errorMsg = error;
            }
        );
    }

    onIsPracticeRequiredChange(ev: MatRadioChange): void {
        console.log(ev);
        if (ev.value === "1") {
            this.showPracticeList = true;
        } else {
            this.showPracticeList = false;
        }
    }

    onClickPracticeSubmit(): void {
        // this.practicesPool.push(
        //     new ModulesPoolPracticeTest(this.practiceItemName)
        // );
        // this.practiceItemName = "";
    }

    onRemovePracticeItem(idx: number): void {
        this.practicesPool.splice(idx, 1);
    }
}
