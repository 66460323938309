import {
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation,
    OnDestroy,
    ElementRef,
    Renderer2,
    HostListener
} from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatPaginator, MatDialog } from '@angular/material';
import { Subject } from 'rxjs';
import { PaginationPage } from 'app/shared/pagination';
import { SelectionModel } from '@angular/cdk/collections';
import { DashboardpoolDs } from './model/dashboardpool-ds';
import { Dashboardpool, InvitationCode } from './model/dashboardpool';
import { ModulesService } from '../modules/modules.service';
import { Router } from '@angular/router';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { tap } from 'rxjs/operators';
import { ApiResponse } from 'app/shared/api-response';
import {
    CommonDialog,
    DialogEvent,
} from 'app/shared/component/dialog/common-dialog/common-dialog';
import { CommonDialogService } from 'app/shared/component/dialog/common-dialog/common-dialog.service';
import { PracticaltestComponent } from './practicaltest/practicaltest.component';
import { ProjectComponent } from './project/project.component';
import { RatingComponent } from './rating/rating.component';
import { StorageService } from 'app/shared/storage.service';
import { AppSettings } from 'app/app.settings';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../../shared/auth/auth.service';
import { catchError, flatMap } from 'rxjs/operators';
import {
    startOfDay,
    format,
} from 'date-fns';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { finalize, map } from 'rxjs/operators';
import { DialogTrainingDetailComponent } from '../../layout/components/quick-panel/dialog-training-detail/dialog-training-detail.component';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-dashboardpool',
    templateUrl: './dashboardpool.component.html',
    styleUrls: ['./dashboardpool.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class DashboardpoolComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    types: any[];
    searchTextSubject = new Subject<any>();
    page = new PaginationPage();
    dataSource: DashboardpoolDs;
    selection = new SelectionModel<Dashboardpool>(true, []);
    InvitationCode = new InvitationCode();

    nik = null;

    intervalGetToken: any;
    private appSettings = new AppSettings();
    otest = this.appSettings.settings.otest;
    otestRki = this.appSettings.settings.otestRki;

    date: Date;
    notes: any[];
    settings: any;

    @ViewChild('calendar', { static: false }) calendar: ElementRef;
    view: string;
    viewDate: Date;
    selectedDay: any;
    apiEndpoint: string;
    isLoading = true;
    formattedDate: string;
    position = 0;
    listDataModuleUpcoming = [];
    listDataPublicModule = [];
    listDataBanner = [];
    listDataHistoriModule = [];
    isMobile: boolean;

    isPopUpDialog = false;
    isPopUpDialogType = "";
    isPopUpDialogMessage = "";
    isRetestId = [];

    customOptions: OwlOptions = {
        loop: false,
        autoplay: false,
        center: false,
        dots: false,
        autoHeight: false,
        autoWidth: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        navSpeed: 700,
        nav: true,
        navText: ['<', '>'],
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            940: {
                items: 4,
            },
        },
    }

    customOptionsHeader: OwlOptions = {
        loop: true,
        autoplay: true,
        // center: true,
        dots: true,
        nav: false,
        // autoHeight: true,
        responsive: {
            0: {
                items: 1,
                nav: false,
                dots: false,
                margin: 20,
            },
            576: {
                items: 1,
                nav: false,
                dots: true,
                margin: 40,
            },
            768: {
                items: 1,
                nav: false,
                margin: 70,
            },
        },
    }

    carouselData: any[] = [
        {
            text: 'Slide 1',
            src: 'assets/images/350x450&text=1.png',
            dataMerge: 2,
            width: 300,
            dotContent: 'text1',
            dataHash: 'one',
        },
        {
            text: 'Slide 2',
            src: 'assets/images/350x650&text=2.png',
            dataMerge: 1,
            width: 500,
            dotContent: 'text2',
            dataHash: 'two',
        },
        {
            text: 'Slide 3',
            src: 'assets/images/350x250&text=3-fallback.png',
            dataMerge: 3,
            dotContent: 'text3',
            dataHash: 'three',
        },
        {
            text: 'Slide 4',
            src: 'assets/images/350x250&text=4.png',
            width: 450,
            dotContent: 'text4',
            dataHash: 'four',
        },
        {
            text: 'Slide 5',
            src: 'assets/images/350x250&text=5.png',
            dataMerge: 2,
            dotContent: 'text5',
            dataHash: 'five',
        },
        // { text: 'Slide 6', dotContent: 'text5'},
        // { text: 'Slide 7', dotContent: 'text5'},
        // { text: 'Slide 8', dotContent: 'text5'},
        // { text: 'Slide 9', dotContent: 'text5'},
        // { text: 'Slide 10', dotContent: 'text5'},
    ];

    constructor(
        private serviceModules: ModulesService,
        public router: Router,
        private splaceScreen: FuseSplashScreenService,
        public dialog: MatDialog,
        public commonDialogService: CommonDialogService,
        private storageService: StorageService,
        private dom: DomSanitizer,
        private authService: AuthService,
        private _httpClient: HttpClient,
        private datePipe: DatePipe,
        private renderer: Renderer2
    ) {
        this.nik = this.storageService.get('username');
        this.date = new Date();
        this.apiEndpoint = this.appSettings.getApiEndpoint('modulesPool');
        this.viewDate = new Date();
        this.selectedDay = { date: startOfDay(new Date()) };
        this.apiEndpoint = this.appSettings.getApiEndpoint('modulesPool');
        this.formattedDate = this.datePipe.transform(this.viewDate, 'MMMM yyyy');
        this.checkWindowSize();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.checkWindowSize();
    }

    checkWindowSize() {
        this.isMobile = window.innerWidth < 768;
    }

    ngOnInit() {
        this.serviceModules.getModulesType().subscribe((response) => {
            this.types = response;
        });
        this.dataSource = new DashboardpoolDs(this.serviceModules);
        this.loadData();
        // this.dataSource.loadDataDashboarPool(this.page);

        setTimeout(() => {
            // this.splaceScreen.hide();
        }, 3000);

        this.intervalGetToken = setInterval(() => {
            this.getToken();
        }, 10000);
    }

    ngOnDestroy() {
        clearInterval(this.intervalGetToken);
        this.loadData();
    }

    loadData(): void {
        this.page.offset = this.paginator ? this.paginator.pageIndex : 0;
        this.page.limit = this.paginator ? this.paginator.pageSize : 20;
        this.dataSource.loadDataDashboarPool(this.page);
        this.isPopUpDialog = false;
        this.isPopUpDialogMessage = "";
        this.isPopUpDialogType = "";
        this.isRetestId = [];
        this.dataSource.subject.subscribe((data) => {
            console.log('Loaded data:', data);

            this.isPopUpDialog = false;
            this.isPopUpDialogMessage = "";
            if (data && data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    data[i].background = this.urlSanitizer(data[i].background);

                    if (data[i].post_test && data[i].post_test.is_fail === '1') {
                        this.isPopUpDialog = true;
                        this.isPopUpDialogType = "Retest";
                        this.isPopUpDialogMessage = "Mohon maaf nilai test anda dibawah standar. Silahkan akses kembali modul dan lakukan post test kembali";
                        this.isRetestId.push(data[i].post_test.id);
                    }

                    if (data[i].post_test && data[i].post_test.is_success === '1') {
                        this.isPopUpDialog = true;
                        this.isPopUpDialogType = "Success";
                        this.isPopUpDialogMessage = "Selamat anda telah LULUS program INDUCTION karyawan baru. Silahkan klik link berikut untuk menyelesaikan program induction ini : https://forms.office.com/r/U1jvdhQ0yT?origin=lprLink";
                        this.isRetestId.push(data[i].post_test.id);
                    }
                }
            }

            if (this.isPopUpDialog) {
                const dialog = new CommonDialog({
                    title: this.isPopUpDialogType,
                    message: this.isPopUpDialogMessage,
                    isCancelable: false
                });
                this.commonDialogService.open(dialog).subscribe((result) => {
                    if (result.isSubmit()) {
                        this.submitRetest();
                    }
                });
            }

        });

        this.selection.clear();

    }

    search(query: string): void {
        this.page.search = query;
        this.dataSource.loadDataDashboarPool(this.page);
    }

    ngAfterViewInit(): void {
        // this.paginator.page.pipe(tap(() => this.loadData())).subscribe();
        // this.searchTextSubject.subscribe((val) => {
        //     setTimeout(() => console.log(val), 1000);
        // });

        // setTimeout(() => {
        //     this.now();
        // }, 3000);
        // this.getModuleUpComing();
        this.getPublicTraining();
        this.getBanner();
        // this.getHistoriTraining();
    }

    modulesPoolList(id: string, emp_id): void {
        this.router.navigate(['dashboard/' + id + '/' + emp_id]);
    }

    openPracticalTest(ref: Dashboardpool): void {
        const dialogRef = this.dialog.open(PracticaltestComponent, {
            data: ref,
            panelClass: 'elearning-dialog-half',
        });

        dialogRef
            .afterClosed()
            .subscribe((result: DialogEvent<ApiResponse>) => {
                if (result instanceof DialogEvent) {
                    if (result.isSubmit()) {
                        this.responseDialog(result.getData());
                    }
                } else {
                    this.loadData();
                }
            });
    }

    responseDialog(response: ApiResponse): void {
        const dialog = new CommonDialog({
            title: response.title,
            message: response.message,
        });
        this.commonDialogService.open(dialog).subscribe(() => this.loadData());
    }

    takeTest(data: Dashboardpool, type: string): void {
        var winOpen = null;
        var height = screen.availHeight;
        var width = screen.availWidth;
        switch (type) {
            case 'pre':
                if (data.pre_test) {
                    if (data.bu === 'RK') {
                        winOpen = window.open(
                            `${this.otestRki}${this.nik}/${data.pre_test.id}`,
                            'MsgWindow',
                            'width=' + width + ',height=' + height
                        );
                    } else {
                        winOpen = window.open(
                            this.otest + data.pre_test.invitation_code,
                            'MsgWindow',
                            'width=' + width + ',height=' + height
                        );
                    }
                } else {
                    this.serviceModules
                        .setSession(data, type)
                        .subscribe((response) => {
                            winOpen = window.open(
                                this.otest + response.code,
                                'MsgWindow',
                                'width=' + width + ',height=' + height
                            );
                        });
                }

                break;

            case 'post':
                if (data.post_test) {
                    if (data.bu === 'RK') {
                        winOpen = window.open(
                            `${this.otestRki}${this.nik}/${data.post_test.id}`,
                            'MsgWindow',
                            'width=' + width + ',height=' + height
                        );
                    } else {
                        winOpen = window.open(
                            this.otest + data.post_test.invitation_code,
                            'MsgWindow',
                            'width=' + width + ',height=' + height
                        );
                    }
                } else {
                    this.serviceModules
                        .setSession(data, type)
                        .subscribe((response) => {
                            winOpen = window.open(
                                this.otest + response.code,
                                'MsgWindow',
                                'width=' + width + ',height=' + height
                            );
                        });
                }
                break;
            case 'confirm':
                const dialog = new CommonDialog({
                    title: 'Retest',
                    message:
                        'Post Retest Belum di konfirmasi, silahkan hubungi Trainer',
                });
                this.commonDialogService
                    .open(dialog)
                    .subscribe(() => this.loadData());
                break;
        }

        if (winOpen) {
            var timer = setInterval(() => {
                if (winOpen.closed) {
                    clearInterval(timer);
                    this.loadData();
                }
            }, 1000);
        }
    }

    takeProject(ref: Dashboardpool): void {
        const dialogRef = this.dialog.open(ProjectComponent, {
            data: ref,
            panelClass: 'elearning-dialog-half',
        });

        dialogRef
            .afterClosed()
            .subscribe((result: DialogEvent<ApiResponse>) => {
                if (result instanceof DialogEvent) {
                    if (result.isSubmit()) {
                        this.responseDialog(result.getData());
                    }
                } else {
                    this.loadData();
                }
            });
    }

    stringToNumber(str) {
        return parseFloat(str);
    }

    errorImages(event, el): void {
        event.target.src = 'assets/images/cards/card2.jpg';
    }

    rating(ref): void {
        const dialogRef = this.dialog.open(RatingComponent, {
            data: ref,
            panelClass: 'elearning-dialog-half',
        });

        dialogRef
            .afterClosed()
            .subscribe((result: DialogEvent<ApiResponse>) => {
                if (result instanceof DialogEvent) {
                    if (result.isSubmit()) {
                        this.responseDialog(result.getData());
                    }
                } else {
                    this.loadData();
                }
            });
    }

    urlSanitizer(url) {
        if (!url) {
            return '';
        }
        if (url) {
            url = url.replace('watch?v=', 'embed/');
        }
        return this.dom.bypassSecurityTrustResourceUrl(url);
    }

    async getToken(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.authService.refreshToken().subscribe((response) => {
                if (response.token) {
                    this.authService.setToken(response.token, '');
                    const decodeToken = this.authService.decodeToken(response.token);
                    this.storageService.set('tokenID', decodeToken.tokenID);
                } else {
                    this.authService.logout();
                    this.router.navigate(['login']);
                }
            });
        });
    }

    async generateCalendar() {
        this.calendar.nativeElement.innerHTML = '';
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        daysOfWeek.forEach(day => {
            const dayHeader = document.createElement('div');
            dayHeader.textContent = day;
            dayHeader.style.fontWeight = 'bold';
            this.calendar.nativeElement.appendChild(dayHeader);
        });

        const currentDate = this.viewDate;
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        const startDay = firstDayOfMonth.getDay();
        const daysInMonth = lastDayOfMonth.getDate();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();

        for (let i = 0; i < startDay; i++) {
            const emptyCell = document.createElement('div');
            this.calendar.nativeElement.appendChild(emptyCell);
        }

        for (let i = 1; i <= daysInMonth; i++) {
            const dayDiv = document.createElement('div');
            dayDiv.textContent = i.toString();
            dayDiv.dataset.date = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(i).padStart(2, '0')}`;
            this.calendar.nativeElement.appendChild(dayDiv);

            const today = new Date();
            if (year === today.getFullYear() && month === today.getMonth() && i === today.getDate()) {
                this.renderer.setStyle(dayDiv, 'background-color', '#8ed9a680');
            }
        }



    }

    now() {
        this.viewDate = new Date();
        this.generateCalendar();
        this.getEvents();
    }

    goToPreviousMonth() {
        this.viewDate.setMonth(this.viewDate.getMonth() - 1);
        this.generateCalendar();
        this.getEvents();
    }

    goToNextMonth() {
        this.viewDate.setMonth(this.viewDate.getMonth() + 1);
        this.generateCalendar();
        this.getEvents();
    }


    getEvents() {
        const month = format(this.viewDate, 'MM').toString();
        const year = format(this.viewDate, 'YYYY');
        this.formattedDate = this.datePipe.transform(this.viewDate, 'MMMM yyyy');

        const params = new HttpParams()
            .set('action', 'getMyCalendarData')
            .set('year', year)
            .set('month', month);

        this._httpClient
            .get<any>(
                this.apiEndpoint, {
                params: params,
            }
            )
            .pipe(
                map((data) => data),
                finalize(() =>
                    setTimeout(() => (this.isLoading = false), 1000)
                )
            )
            .subscribe(
                (data) => {

                    let date_event = [];
                    let current = new Date();
                    for (let i = 0; i < data.length; i++) {
                        let start = new Date(data[i].start);
                        let end = new Date(data[i].end);
                        let range_date = this.getDates(start, end);
                        for (let j = 0; j < range_date.length; j++) {
                            this.createEvent(range_date[j], data[i].title, (current >= range_date[j]) ? ((current == range_date[j]) ? '#8ed9a680' : '#d98e8e80') : '#8ecfd980')
                            let events = [];
                            events.push(data[i].title)
                            date_event.push({
                                date: range_date[j],
                                event: events,
                                color: (current >= range_date[j]) ? '#d98e8e80' : '#8ecfd980'
                            });
                        }
                    }
                },
                (error) => {

                }
            );
    }

    getDates(startDate, endDate) {
        const dates = [];
        let currentDate = new Date(startDate);
        const addDays = function (days) {
            const date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        };
        while (currentDate <= endDate) {
            dates.push(currentDate);
            currentDate = addDays.call(currentDate, 1);
        }
        return dates;
    }

    createEvent(date, description, color) {
        const bullet = document.createElement('span');
        bullet.classList.add('dot');

        const dayDiv = Array.from(this.calendar.nativeElement.children).find((div: HTMLElement) => div.innerText === String(date.getDate()));
        if (dayDiv) {
            this.renderer.setStyle(dayDiv, 'background-color', color);
            // this.renderer.appendChild(dayDiv, bullet);
        }
    }

    getModuleUpComing() {
        const params = new HttpParams()
            .set('action', 'getModuleUpComing');
        this.listDataModuleUpcoming = [];

        this._httpClient
            .get<any>(
                this.apiEndpoint, {
                params: params,
            }
            )
            .pipe(
                map((data) => data),
                finalize(() =>
                    setTimeout(() => (this.isLoading = false), 1000)
                )
            )
            .subscribe(
                (data) => {
                    this.listDataModuleUpcoming = data;

                },
                (error) => {

                }
            );
    }

    getPublicTraining() {
        const params = new HttpParams()
            .set('action', 'getPublicTraining');
        this.listDataPublicModule = [];

        this._httpClient
            .get<any>(
                this.apiEndpoint, {
                params: params,
            }
            )
            .pipe(
                map((data) => data),
                finalize(() =>
                    setTimeout(() => (this.isLoading = false), 1000)
                )
            )
            .subscribe(
                (data) => {
                    this.listDataPublicModule = data;

                },
                (error) => {

                }
            );
    }

    getHistoriTraining() {
        const params = new HttpParams()
            .set('action', 'getHistoriTraining');
        this.listDataHistoriModule = [];

        this._httpClient
            .get<any>(
                this.apiEndpoint, {
                params: params,
            }
            )
            .pipe(
                map((data) => data),
                finalize(() =>
                    setTimeout(() => (this.isLoading = false), 1000)
                )
            )
            .subscribe(
                (data) => {
                    this.listDataHistoriModule = data;

                },
                (error) => {

                }
            );
    }

    detailTraining(ref: any): void {
        const dialogRef = this.dialog.open(DialogTrainingDetailComponent, {
            data: ref,
            panelClass: 'elearning-dialog',
        });

        dialogRef
            .afterClosed()
            .subscribe((result: DialogEvent<ApiResponse>) => {
                this.ngAfterViewInit();
            });
    }

    getBanner() {
        const params = new HttpParams()
            .set('action', 'getBanner');
        this.listDataBanner = [];

        this._httpClient
            .get<any>(
                this.apiEndpoint, {
                params: params,
            }
            )
            .pipe(
                map((data) => data),
                finalize(() =>
                    setTimeout(() => (this.isLoading = false), 1000)
                )
            )
            .subscribe(
                (data) => {
                    this.listDataBanner = data;

                },
                (error) => {

                }
            );
    }

    submitRetest(): void {

        this.serviceModules.retestPost(this.isRetestId).subscribe(response => {
            this.responseDialog(new ApiResponse(response));

        });
    }
}
