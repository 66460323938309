import { Component, OnInit, Inject } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { Helper } from 'app/shared/helper';
import { MatDialogRef, MAT_DIALOG_DATA, MatAutocompleteSelectedEvent } from '@angular/material';
import { Userexternal, Project } from '../model/userexternal';
import { UserexternalService } from '../userexternal.service';
import { debounceTime, distinctUntilChanged, tap, switchMap, takeUntil, finalize } from 'rxjs/operators';
import { Profiles } from 'app/shared/auth/auth';
import { DialogEvent } from 'app/shared/component/dialog/common-dialog/common-dialog';
import { ModulespoolService } from 'app/main/modulespool/modulespool.service';
import { Modulespool } from 'app/main/modulespool/model/modulespool';
import { ModulesPoolPracticeTest } from 'app/main/modules-pool/model/modules-pool';
import { AppSettings } from 'app/app.settings';
import { CompetencyPool, SkillIndicatorPool } from 'app/shared/interfaces/references';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-form-userexternal',
  templateUrl: './form-userexternal.component.html',
  styleUrls: ['./form-userexternal.component.scss'],
  animations: fuseAnimations
})
export class FormUserexternalComponent implements OnInit {
  form: FormGroup;
  isEdit = false;
  isProject = false;
  isPractice = false;
  unsubscribeAll = new Subject();
  isLoading = false;
  helper = new Helper();
  filteredModulesPool: Modulespool[] = [];
  filteredEmploye: Profiles[] = [];
  selectedModulesPool = new Modulespool;
  selectedProfile: any;
  practicalList: ModulesPoolPracticeTest[] = [];
  listUser: any[] = [];

  projectTitle = "";
  startDateProject = "";
  endDateProject = "";
  target = "";
  baseline = "";
  learningType: string;
  preTestId: string;
  postTestId: string;
  pre_duration: string;
  post_duration: string;
  projectItem: Project[] = [];
  listDataCertificate: any[] = [];
  private appSettings = new AppSettings();
  apiEndpoint: string;
  competencyPool: CompetencyPool[] = [];
  skillIndicatorPool: SkillIndicatorPool[] = [];

  progressUpload = 0;

  constructor(
    public dialogRef: MatDialogRef<FormUserexternalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Userexternal,
    private formBuilder: FormBuilder,
    private service: UserexternalService,
    private empModulePoolService: UserexternalService,
    private modulesPoolService: ModulespoolService
  ) {
    this.isEdit = this.data ? true : false;
  }

  ngOnInit() {
    this.loadDataCertificate();
    if (this.isEdit) {
      console.log(this.data);


      this.form = this.formBuilder.group({
        id: new FormControl(this.data.id),
        type: new FormControl(null),
        id_card_number: new FormControl(this.data.id_card_number),
        first_name: new FormControl(this.data.first_name),
        username: new FormControl(this.data.username),
        last_name: new FormControl(this.data.last_name),
        email_address: new FormControl(this.data.email_address),
        phone_number: new FormControl(this.selectedModulesPool),
      });

    }
    else {
      this.form = this.formBuilder.group({
        first_name: new FormControl('', Validators.required),
        type: new FormControl('create'),
        id_card_number: new FormControl('', Validators.required),
        username: new FormControl('', Validators.required),
        last_name: new FormControl('', Validators.required),
        startDatePractice: new FormControl(''),
        endDatePractice: new FormControl(''),
        phone_number: new FormControl(''),
        email_address: new FormControl(''),
      });
    }

  }

  displayFnMp(data: Modulespool): string {

    if (data) {
      return data.pool_name;
    }
  }

  onSelectedMp(event: MatAutocompleteSelectedEvent): void {
    this.isProject = false;
    this.form.get('email_address').setValue(event.option.value.id);

    this.practicalList = event.option.value.practical_test_list;
    if (event.option.value.is_project_required == '1') {
      this.isProject = true;
    }

    this.learningType = event.option.value.learning_type;
    this.competencyPool = event.option.value.competency_pool;
    this.skillIndicatorPool = event.option.value.skill_indicator_pool;
    this.preTestId = event.option.value.pre_test_id;
    this.postTestId = event.option.value.post_test_id;
    this.pre_duration = event.option.value.pre_duration;
    this.post_duration = event.option.value.post_duration;

    let employees = event.option.value.employee;

    for (let employee of employees) {
      this.listUser.push(
        new Profiles(employee.first_name, employee.nama)
      );
    }

    this.form.get('first_name').setValue(this.listUser);
  }

  initAutoCompleteMp(): void {
    this.form.get('phone_number')
      .valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.isLoading = true)),
        switchMap(value =>
          this.modulesPoolService.searchDataModulesPool(value).pipe(
            takeUntil(this.unsubscribeAll),
            finalize(() => this.isLoading = false)
          )
        )
      ).subscribe(data => {
        this.filteredModulesPool = data;
      });
  }

  initAutoCompleteEmp(): void {
    this.form.get('employee')
      .valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.isLoading = true)),
        switchMap(value =>
          this.empModulePoolService.searchDataEmployee(value, this.form.value.id_card_number).pipe(
            takeUntil(this.unsubscribeAll),
            finalize(() => this.isLoading = false)
          )
        )
      ).subscribe(users => {
        this.filteredEmploye = users;
      });
  }

  onSubmit(): void {

    if (this.listUser.length == 0) {
      this.listUser.push({
        id_card_number: this.form.value.id_card_number,
        username: this.form.value.username,
        email_address: this.form.value.email_address,
        first_name: this.form.value.first_name,
        last_name: this.form.value.last_name,
        phone_number: this.form.value.phone_number
      });
    }

    const payload = {
      id_card_number: this.form.value.id_card_number,
      username: this.form.value.username,
      first_name: this.form.value.first_name,
      last_name: this.form.value.last_name,
      email_address: this.form.value.email_address,
      phone_number: this.form.value.phone_number,
      user_external: this.listUser,
    };

    this.service.createData(payload).subscribe(response => {
      this.dialogRef.close(new DialogEvent('submit', response));
    });

  }

  onUpdate(): void {
    const payload = {
      id: this.form.value.id,
      id_card_number: this.form.value.id_card_number,
      username: this.form.value.username,
      first_name: this.form.value.first_name,
      last_name: this.form.value.last_name,
      email_address: this.form.value.email_address,
      phone_number: this.form.value.phone_number,
    }

    this.service.updateData(payload).subscribe(response => {
      this.dialogRef.close(new DialogEvent('submit', response));
    })
  }

  onClickProjectSubmit(): void {

  }

  onRemoveProjectItem(idx: number): void {
    this.projectItem.splice(idx, 1);
  }

  onRemoveEmployee(idx: number): void {
    this.listUser.splice(idx, 1);
  }

  downloadTemplate(): void {
    this.apiEndpoint = this.appSettings.getApiEndpoint('empModulePool');
    window.open(this.apiEndpoint + '/templateUploadUserExternal', '_blank');
  }

  uploadFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.apiEndpoint = this.appSettings.getApiEndpoint('files');

      const el = file;
      const formData = new FormData();
      // formData.append('id', 'test');
      formData.append('action', "importFileUserExternal");
      formData.append('files', file, file.name);

      this.service.uploadFile(this.apiEndpoint, formData).subscribe(res => {
        for (let i = 0; i < res.data.data.length; i++) {
          this.listUser.push({
            id_card_number: res.data.data[i].id_card_number,
            username: res.data.data[i].username,
            email_address: res.data.data[i].email_address,
            first_name: res.data.data[i].first_name,
            last_name: res.data.data[i].last_name,
            phone_number: res.data.data[i].phone_number
          });
          this.form.get('id_card_number').setValue(res.data.data[i].id_card_number);
          this.form.get('username').setValue(res.data.data[i].username);
          this.form.get('email_address').setValue(res.data.data[i].email_address);
          this.form.get('first_name').setValue(res.data.data[i].first_name);
          this.form.get('last_name').setValue(res.data.data[i].last_name);
          this.form.get('phone_number').setValue(res.data.data[i].phone_number);
        }
      });

    }
  }

  loadDataCertificate() {
    this.service.loadDataCertificate().subscribe((response) => {
      this.listDataCertificate = response;
    });
  }



}
