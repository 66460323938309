import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserexternalComponent } from './userexternal.component';
import { FormUserexternalComponent } from './form-userexternal/form-userexternal.component';
import { AuthGuard } from 'app/shared/auth/auth.guard';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatProgressBarModule, MatInputModule, MatToolbarModule, MatTableModule, MatPaginatorModule, MatSortModule, MatIconModule, MatProgressSpinnerModule, MatButtonModule, MatDialogModule, MatSelectModule, MatFormFieldModule, MatCheckboxModule, MatSnackBarModule, MatTooltipModule, MatAutocompleteModule, MatTabsModule, MatRadioModule, MatDatepickerModule, MatDividerModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FuseWidgetModule } from '@fuse/components';
import { CreateCertificateComponent } from './create-certificate/create-certificate.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatMenuModule } from '@angular/material/menu';


const routes = [
  {
    path: 'userexternal',
    component: UserexternalComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [UserexternalComponent, FormUserexternalComponent, CreateCertificateComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    MatProgressBarModule,
    MatInputModule,
    MatToolbarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatAutocompleteModule,
    BrowserAnimationsModule,
    MatRadioModule,
    MatDatepickerModule,
    MatTabsModule,
    FuseWidgetModule,
    MatDividerModule,
    MatSelectFilterModule,
    MatMenuModule
  ],
  exports: [
    RouterModule
  ],
  entryComponents: [
    FormUserexternalComponent,
    CreateCertificateComponent,
  ]
})
export class UserexternalModule { }
