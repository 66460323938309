export const locale = {
    lang: 'tr',
    data: {
        NAV: {
            APPLICATIONS: 'Home',
            MODULES: 'Training Modules',
            SAMPLE: {
                TITLE: 'Contoh',
                BADGE: '15',
            },

            ACCOUNT: {
                TITLE: 'Akun',
            },

            MODULESTYPE: {
                TITLE: 'Tipe Modul',
            },

            ELMODULES: {
                TITLE: 'Modul Pelatihan',
            },

            DASHBOARD: {
                TITLE: 'Dashboard',
            },

            MODULESPOOL: {
                TITLE: 'Modul Kelompok Modul',
            },

            EMP_MODULES_POOL: {
                TITLE: 'Kelompok Modul Karyawan',
            },
            TRAINING_CALENDAR: {
                TITLE: 'Kalender Pelatihan',
            },
            DASHBOARD_ADMIN: {
                TITLE: 'Dashboard',
            },
        },
    },
};
