import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { PracticalTest } from 'app/main/empmodulepool/model/empmodulepool';
import { ModulesService } from 'app/main/modules/modules.service';
import { EmpmodulepoolService } from 'app/main/empmodulepool/empmodulepool.service';
import { fuseAnimations } from '@fuse/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject } from 'rxjs';
import { CommonDialog } from 'app/shared/component/dialog/common-dialog/common-dialog';
import { CommonDialogService } from 'app/shared/component/dialog/common-dialog/common-dialog.service';
import { AppSettings } from 'app/app.settings';
import { ModulespoolService } from 'app/main/modulespool/modulespool.service';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { finalize, map } from 'rxjs/operators';

@Component({
  selector: 'app-dialog-training-detail',
  templateUrl: './dialog-training-detail.component.html',
  styleUrls: ['./dialog-training-detail.component.scss'],
  animations: fuseAnimations,
})
export class DialogTrainingDetailComponent implements OnInit {
  toggleDelete = false;
  selection = new SelectionModel<PracticalTest>(true, []);
  deleteToggle = new BehaviorSubject<boolean>(this.toggleDelete);
  TrainingDetailList: any[];
  displayedColumns = ["select", "no", "name", "periode", "isCrewCheck", "dateChecked", "score"];
  apiEndpoint: string;
  private appSettings = new AppSettings();
  data_emp: any;
  isLoading = true;

  form: FormGroup;
  modulesPoolList: any[] = [];


  constructor(
    public dialogRef: MatDialogRef<PracticalTest>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private serviceEmpModulesPool: EmpmodulepoolService,
    public commonDialogService: CommonDialogService,
    public snackBar: MatSnackBar,
    private service: EmpmodulepoolService,
    private _httpClient: HttpClient,
    private formBuilder: FormBuilder,
  ) {
    this.getDetail(data.id);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      type_data: new FormControl(this.data.type_data),
      pool_name: new FormControl(''),
      description: new FormControl(''),
      bu: new FormControl(''),
      pool_type: new FormControl(''),
      post_parameter: new FormControl(''),
      post_duration: new FormControl('', Validators.maxLength(180)),
      pre_duration: new FormControl('', Validators.maxLength(180)),
      pre_test_pool: new FormControl(''),
      post_test_pool: new FormControl(''),
      is_practice_required: new FormControl(false),
      is_project_required: new FormControl(false),
      is_restriction: new FormControl(false),
      modules_search: new FormControl(''),
      is_autocheck_practice: new FormControl(false),
      learning_type: new FormControl(0),
      is_pre_test_required: new FormControl(false),
      is_post_test_required: new FormControl(false),
      sub_area: new FormControl(""),
      learning_duration: new FormControl(100),
      certificate_configurations_id: new FormControl(''),
      background: new FormControl(""),
    });
  }

  getDetail(id) {
    const params = new HttpParams()
      .set('action', 'getDetail')
      .set('id', id);

    this._httpClient
      .get<any>(
        this.appSettings.getApiEndpoint('modulesPool'), {
        params: params,
      }
      )
      .pipe(
        map((data) => data),
        finalize(() =>
          setTimeout(() => (this.isLoading = false), 1000)
        )
      )
      .subscribe(
        (data) => {
          this.initData(data);
        },
        (error) => {

        }
      );
  }

  initData(detail: any): void {
    this.form = this.formBuilder.group({
      type_data: new FormControl(this.data.type_data),
      id: new FormControl(detail.id),
      pool_name: new FormControl(detail.pool_name),
      description: new FormControl(
        detail.description),
      bu: new FormControl(detail.bu),
      pool_type: new FormControl(detail.pool_type),
      post_parameter: new FormControl(
        detail.post_parameter),
      post_duration: new FormControl(
        detail.post_duration,
        Validators.maxLength(180)
      ),
      pre_duration: new FormControl(
        detail.pre_duration,
        Validators.maxLength(180)
      ),
      pre_test_pool: new FormControl((detail.pre_test_pool) ? true : false),
      post_test_pool: new FormControl((detail.post_test_pool) ? true : false),
      is_project_required: new FormControl(
        (detail.is_project_required == '1') ? true : false),
      is_restriction: new FormControl(
        (detail.is_restriction == '1') ? true : false),
      modules_search: new FormControl(''),
      is_autocheck_practice: new FormControl(
        detail.is_autocheck_practice),
      is_pre_test_required: new FormControl(),
      is_post_test_required: new FormControl(),
      learning_type: new FormControl(detail.learning_type),
      sub_area: new FormControl(detail.sub_area),
      learning_duration: new FormControl(detail.learning_duration),
      certificate_configurations_id: new FormControl(detail.certificate_configurations_id),
      background: new FormControl(
        detail.background),
    });

    this.modulesPoolList = detail.modules_pool_list;
  }

  loadData(): void {

  }

  submitCheck(): void {
    const dialog = new CommonDialog({
      title: "Take this Module",
      message: "Are you sure want to take this item ?",
      isCancelable: true,
      textButtonCancel: "CANCEL"
    });

    this.commonDialogService.open(dialog).subscribe(val => {
      if (val.isSubmit()) {
        // this.serviceEmpModulesPool.updateTrainingDetailTest(this.TrainingDetailList).subscribe(val => {
        //   // this.loadData();
        //   this.dialogRef.close(); 
        //   this.snackBar.open(val.message, "OK", {
        //     duration: 90000,
        //     horizontalPosition: "end",
        //     verticalPosition: "top",
        //     panelClass: ["snackbar-info"]
        //   });
        // });

        this._httpClient
          .post<any>(
            this.appSettings.getApiEndpoint('empModulePool'), {
            action: 'takeItModule',
            module_pool_id: this.data.id,
          }
          )
          .pipe(
            map((data) => data),
            finalize(() =>
              setTimeout(() => (this.isLoading = false), 1000)
            )
          )
          .subscribe(
            (data) => {
              this.dialogRef.close();
              this.snackBar.open('Request Success', "OK", {
                duration: 90000,
                horizontalPosition: "end",
                verticalPosition: "top",
                panelClass: ["snackbar-info"]
              });
            },
            (error) => {
              this.snackBar.open('Request Failed', "OK", {
                duration: 90000,
                horizontalPosition: "end",
                verticalPosition: "top",
                panelClass: ["snackbar-info"]
              });
            }
          );
      }
      if (val.isCancel() || val.isDestroy()) {
        this.snackBar.open("Request Cancelled", "OK", {
          duration: 90000,
          horizontalPosition: "end",
          verticalPosition: "top",
          panelClass: ["snackbar-info"]
        });
      }
    })

  }
}
