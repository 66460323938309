import { AfterViewInit, Component, Input, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { uniqueId } from 'lodash';
import { AssessmentItem } from 'app/main/dashboard-admin/dashboard-admin';
import { References } from 'app/shared/interfaces/references';
import { ModulesPoolService } from 'app/main/modules-pool/modules-pool.service';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-clustered-column-chart',
  templateUrl: './clustered-column-chart.component.html',
  styleUrls: ['./clustered-column-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ClusteredColumnChartComponent implements OnInit, AfterViewInit, OnDestroy {

  private chart: am4charts.XYChart;
  chartId = uniqueId('cluster_column_chart');
  @Input('data') data: AssessmentItem;

  dataModal:References[];
  
  constructor(
    private zone: NgZone,
    private _service: ModulesPoolService,
  ) {  

  }
  
  ngOnInit():void { }

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
        this.initChart();
    });
  }

  ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
        if (this.chart) {
            this.chart.dispose();
        }
    });
  }

  initChart(): void {
    let chart = am4core.create(this.chartId, am4charts.XYChart);

    chart.responsive.enabled = true;
    // Add percent sign to all numbers
    chart.numberFormatter.numberFormat = "#.#'%'";

    // Add data
    chart.data = this.data.data;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = this.data.category;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.labels.template.rotation = 60;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = this.data.title;
    // valueAxis.title.fontWeight = "800";

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = this.data.valueY.value0;
    series.dataFields.categoryX = this.data.valueX;
    series.tooltipText = this.data.valueY.toolTip0;
    series.name = this.data.valueY.nama0;
    // series.clustered = false;

    let series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = this.data.valueY.value1;
    series2.dataFields.categoryX = this.data.valueX;
    series2.tooltipText = this.data.valueY.toolTip1;
    series2.name = this.data.valueY.nama1;
    // series2.clustered = false;
    // series2.columns.template.width = am4core.percent(50);

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineX.disabled = true;
    chart.cursor.lineY.disabled = true;

    // Add legend
    chart.legend = new am4charts.Legend();
    // chart.legend.labels.template.fill = am4core.color('#fff');
    chart.legend.fontSize = 10;
    chart.legend.position = 'top';

    series2.columns.template.events.on("hit", (ev) =>{
      const payload:any = ev.target.dataItem.dataContext;

      this.zone.run(()=>{
        let division:string;
        let department:string;

        switch (this.data.method) {
          case 'assessment_staffup':
            
            if (this.data.division == 'all') {
              division = payload.id; 
              department = this.data.department;   
            }
            else if(this.data.division != 'all' && this.data.department == 'all') {
              department = payload.id;
              division = this.data.division;
            }
            
            this._service.loadNotMeetAssesment(division, department, this.data.year, this.data.company, "assesment_staffup").subscribe(response => {
              console.log(response);
            }); 

            break;
        
          default:
            break;
        }

      })
    }, this)

  }
}
