import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Modulessection } from '../model/modulessection';
import { AppSettings } from 'app/app.settings';
import { DomSanitizer } from '@angular/platform-browser';
import { ModulesService } from '../../modules/modules.service';
import { EmpmodulepoolService } from '../../empmodulepool/empmodulepool.service';

declare var $: any;
@Component({
    selector: 'app-mediacontent',
    templateUrl: './mediacontent.component.html',
    styleUrls: ['./mediacontent.component.scss'],
})
export class MediacontentComponent implements OnInit {
    public item: Modulessection;
    appSettings = new AppSettings();
    doc: string;
    file: any;
    constructor(
        public dialogRef: MatDialogRef<MediacontentComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public serviceModules: ModulesService,
        public service: EmpmodulepoolService,
        private dom: DomSanitizer
    ) {
        this.doc = this.appSettings.getSettings().documentURL;
    }

    ngOnInit() {
        this.item = this.data.ref;
        var url = this.appSettings.getApiEndpoint('files');

        if (this.item.section_type === 'embed') {
            this.file = this.urlSanitizer(this.item.link_file);
        } else {
            this.service
                .getFile(url, {
                    action: 'getFile',
                    token: localStorage.getItem('token'),
                    file_name: this.item.link_file,
                })
                .subscribe(
                    (data) => {
                        const reader = new FileReader();
                        const out = new Blob([data], {
                            type: data.type,
                        });
                        reader.onload = (e) => {
                            console.log(reader.result);
                        };
                        if (
                            this.item.section_type === 'video' ||
                            this.item.section_type === 'picture' ||
                            this.item.section_type === 'audio'
                        ) {
                            this.file = this.urlSanitizer(
                                URL.createObjectURL(out)
                            );
                        } else {
                            this.file = URL.createObjectURL(out);
                        }
                    },
                    (error) => {
                        console.error(error);
                    }
                );
        }
    }

    urlSanitizer(url) {
        if (!url) {
            return '';
        }
        if (url) {
            url = url.replace('watch?v=', 'embed/');
        }
        return this.dom.bypassSecurityTrustResourceUrl(url);
    }
}
