import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from 'app/shared/auth/auth.guard';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { 
  MatProgressBarModule,
  MatInputModule,
  MatToolbarModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatButtonModule,
  MatDialogModule,
  MatSelectModule,
  MatFormFieldModule,
  MatCheckboxModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatAutocompleteModule, 
  MatTabsModule
} from '@angular/material';
import { ModulestypeComponent } from './modulestype.component';
import { FormModulestypeComponent } from './form-modulestype/form-modulestype.component';

const routes = [
  {
    path  : 'modulestype',
    component : ModulestypeComponent,
    canActivate : [AuthGuard]
  }
];

@NgModule({
  declarations: [
    ModulestypeComponent,
    FormModulestypeComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    MatProgressBarModule,
    MatInputModule,
    MatToolbarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatTabsModule
  ],
  exports: [
    RouterModule
  ],
  entryComponents:[
    FormModulestypeComponent
  ]
})
export class ModulestypeModule { }
