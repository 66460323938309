import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyTrainingComponent } from './my-training.component';
import { AuthGuard } from 'app/shared/auth/auth.guard';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatProgressBarModule, MatInputModule, MatToolbarModule, MatTableModule, MatPaginatorModule, MatSortModule, MatIconModule, MatProgressSpinnerModule, MatButtonModule, MatDialogModule, MatSelectModule, MatFormFieldModule, MatCheckboxModule, MatSnackBarModule, MatTooltipModule, MatAutocompleteModule, MatTabsModule, MatRadioModule, MatDatepickerModule, MatDividerModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FuseWidgetModule } from '@fuse/components';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatMenuModule } from '@angular/material/menu';


const routes = [
  {
    path  : 'my-training',
    component : MyTrainingComponent,
    canActivate : [AuthGuard]
  }
];

@NgModule({
  declarations: [MyTrainingComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    MatProgressBarModule,
    MatInputModule,
    MatToolbarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatAutocompleteModule,
    BrowserAnimationsModule,
    MatRadioModule,
    MatDatepickerModule,
    MatTabsModule,
    FuseWidgetModule,
    MatDividerModule,
    MatSelectFilterModule,
    MatMenuModule
  ],
  exports: [
    RouterModule
  ],
  entryComponents:[]
})
export class MyTrainingModule { }
