import { UserexternalModule } from '../userexternal.module';

export class Userexternal {
	id: number;
	username: string;
	plain_text: string;
	el_external_profile_id: string;
	first_name: string;
	last_name: string;
	id_card_number: string;
	id_card_type: string;
	email_address: string;
	phone_number: string;
}

export class PoolList {
	is_mandatory: string;
	module_title: number;
	module_type: number;
	is_need_assesment: string;
	assesment_parameter: string;
	assesment_duration: string;
	score: string;
	assesment_status: string;

}

export class Project {
	id: number;
	project_title: string;
	target: string;
	start_date: string;
	end_date: string;
	baseline: string;
	improvement: string;
	result: string;
	background: string;
	duration: string;

	constructor(id: number, projectTitle: string, target: string, duration: string) {
		this.id = id;
		this.project_title = projectTitle;
		this.target = target;
		this.duration = duration;
	}
}

export class PracticalTest {
	item_name: string;
	start_date: string;
	end_date: string;
	is_crew_check: string;
	date_checked: string;
	score: number;
	scored_by: string;
	scored_date: string;
	score_id: number;
	id: number;
	el_module_pool_id: number;
}

interface ModulesTest {
	id: number;
	score: string;
	test_status: number;
	type: string;
}

