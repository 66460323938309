import { Modulessection } from 'app/main/modulessection/model/modulessection';

export class Modules {
  id: number;
  module_title: string;
  description: string;
  module_type: string;
  id_type: number;
  timestamp: string;
  section:any;
  totalSection: number;
  is_need_assesment: string;
  is_retest: string;
  assesment_question_pool_id: number;
  assesment_question_pool: string;
  assesment_parameter: string;
  assesment_duration: string;
  modules_session: Modulessection;
}
