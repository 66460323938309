import { Component} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'app/shared/storage.service';
import { AuthService } from 'app/shared/auth/auth.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent 
{
  tokenJWT: string;

  constructor(
    private route: ActivatedRoute,
    private storageService: StorageService,
    private authService: AuthService,
    private splaceScreen: FuseSplashScreenService,
    private snackBar: MatSnackBar,
    private _router: Router
  ) { 
    this.route.queryParams
    .subscribe((params)=>{
      if (params.token) {
        this.tokenJWT = params.token;        
        this.storageService.clear();
        this.authService.setCookies();
        this.getDataLogin();
      } else {
        this.authService.logout();
        // this._router.navigate(['sample']);
      }
    });

  }

  ngOnInit() {
  }

  getDataLogin(): void{
    const rs = this.authService.getDataLogin(this.tokenJWT);
console.log(1);
    rs.subscribe(
      data => {
        if (data && data.token) {
          this.splaceScreen.show();
          this.authService.setToken(data.token, null);
          setTimeout(() => {
            this._router.navigate(['dashboardpool']);
          }, 2000);
        }
      },
      error => {
        console.log(error.error.message);
        this._router.navigate(['login']);
      }
    )
  }

}
