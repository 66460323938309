import { NgModule } from '@angular/core';
import { ClusteredColumnChartComponent } from './clustered-column-chart.component';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
  declarations: [ClusteredColumnChartComponent],
  imports: [FuseSharedModule],
  exports: [ClusteredColumnChartComponent],
})
export class ClusteredColumnChartModule { }
