import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PagingAttributes, Pagination, PaginationPage } from 'app/shared/pagination';
import { Banner } from './banner';
import { CollectionViewer } from '@angular/cdk/collections';
import { catchError, finalize } from 'rxjs/operators';
import { BannerService } from '../banner.service';

export class BannerDs implements DataSource<Banner> {
  public pagination = new BehaviorSubject<PagingAttributes>(
    new PagingAttributes()
  );

  public subject = new BehaviorSubject<Banner[]>([]);
  public loadingSubject = new BehaviorSubject<boolean>(false);

  constructor(private service: BannerService) { }

  connect(collectionViewer: CollectionViewer): Observable<Banner[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
    this.loadingSubject.complete();
  }

  loadData(page: PaginationPage): void {
    this.loadingSubject.next(true);
    this.service.loadData(page).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
      .subscribe((data: Pagination<Banner>) => {
        this.subject.next(data.aData);
        this.pagination.next({
          iTotalDisplayRecords: data.iTotalDisplayRecords,
          iTotalRecords: data.iTotalRecords
        });
      });
  }


}
