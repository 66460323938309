import { Component, OnInit, ViewEncapsulation, AfterViewInit, ViewChild } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatPaginator, MatDialog, MatSnackBar } from '@angular/material';
import { Subject, BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AccountsPage } from 'app/account/model/account';
import { ModulestypeDs } from './model/modulestype-ds';
import { SelectionModel } from '@angular/cdk/collections';
import { Modulestype } from './model/modulestype';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { CommonDialogService } from 'app/shared/component/dialog/common-dialog/common-dialog.service';
import { ModulestypeService } from './modulestype.service';
import { FormModulestypeComponent } from './form-modulestype/form-modulestype.component';
import { DialogEvent, CommonDialog } from 'app/shared/component/dialog/common-dialog/common-dialog';
import { ApiResponse } from 'app/shared/api-response';
import { Helper } from 'app/shared/helper';
import { tap } from 'rxjs/operators';
import { References } from 'app/shared/interfaces/references';
import { ModulesPoolService } from "app/main/modules-pool/modules-pool.service";
import { takeUntil, map, finalize } from "rxjs/operators";
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { StorageService } from 'app/shared/storage.service';

@Component({
  selector: 'app-modulestype',
  templateUrl: './modulestype.component.html',
  styleUrls: ['./modulestype.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ModulestypeComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  toggleDelete = false;
  searchTextSubject = new Subject<any>();
  filterSubject = new Subject<any[]>();
  page = new AccountsPage;
  dataSource: ModulestypeDs;
  selection = new SelectionModel<Modulestype>(true, []);
  deleteToggle = new BehaviorSubject<boolean>(this.toggleDelete);
  displayedColumns = ["no", "description", "action"];
  helper = new Helper();

  form: FormGroup;

  companies: Observable<References[]>;
  loadingState = {
    company: false,
  };

  constructor(
    private splaceScreen: FuseSplashScreenService,
    public dialog: MatDialog,
    public commonDialogService: CommonDialogService,
    public snackBar: MatSnackBar,
    public service: ModulestypeService,
    private formBuilder: FormBuilder,
    public serviceModulesPool: ModulesPoolService,
    private storage: StorageService) {
    this.form = this.formBuilder.group({
      company: new FormControl(this.storage.get('empSite')),
    });
  }

  loadData(): void {
    this.page.offset = this.paginator.pageIndex;
    this.page.limit = this.paginator.pageSize;
    this.page.company = this.form.get('company').value;
    this.dataSource.loadData(this.page);
    this.selection.clear();
  }

  ngOnInit() {

    this.loadCompany();


    this.dataSource = new ModulestypeDs(this.service);
    // this.splaceScreen.hide();
    this.dataSource.loadData(this.page);
    this.deleteToggle.subscribe(val => {
      if (val) {
        this.displayedColumns = ["select", ...this.displayedColumns];
      } else {
        const idx = this.displayedColumns.indexOf("select");
        if (idx !== -1) {
          this.displayedColumns.splice(idx, 1);
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.paginator.page.pipe(tap(() => this.loadData())).subscribe();
    this.searchTextSubject.subscribe(val => {
      setTimeout(() => console.log(val)
        , 1000);
    })
  }

  openDialog(ref: Modulestype = null): void {
    const dialogRef = this.dialog.open(FormModulestypeComponent, {
      data: ref,
      panelClass: 'elearning-dialog'
    })

    dialogRef
      .afterClosed()
      .subscribe((result: DialogEvent<ApiResponse>) => {
        console.log(result);
        if (result instanceof DialogEvent) {
          if (result.isSubmit()) {
            this.responseDialog(result.getData());
          }
        } else {
          this.loadData();
        }

      });
  }

  deleteAction(): void {
    const dialog = new CommonDialog({
      title: "Delete Modules Type",
      message: "Are you sure want to delete this Type ?",
      isCancelable: true,
      textButtonCancel: "CANCEL"
    });

    this.commonDialogService.open(dialog).subscribe(val => {
      if (val.isSubmit()) {
        const payload = this.helper.buildDeletePayload(
          this.selection.selected
        );
        this.service
          .deleteData(payload)
          .subscribe(response =>
            this.responseDialog(new ApiResponse(response))
          );
      }
      if (val.isCancel() || val.isDestroy()) {
        this.snackBar.open("Request Cancelled", "OK", {
          duration: 90000,
          horizontalPosition: "end",
          verticalPosition: "top",
          panelClass: ["snackbar-info"]
        });
      }
    })

  }

  search(query: string): void {
    this.page.search = query;
    this.dataSource.loadData(this.page);
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear() : this.dataSource.subject.value.forEach(
        row => this.selection.select(row)
      );
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.pagination.value.iTotalDisplayRecords;
    console.log(numSelected, numRows);

    return numSelected === numRows;
  }

  responseDialog(response: ApiResponse): void {
    const dialog = new CommonDialog({
      title: response.title,
      message: response.message
    });
    this.commonDialogService.open(dialog).subscribe(() => this.loadData());
  }

  loadCompany(): void {
    this.loadingState.company = true;
    this.companies = this.serviceModulesPool.loadCompany().pipe(
      map((result) => result),
      finalize(() => (this.loadingState.company = false))
    );
  }

  loadDataFilter(): void {
    let filter: Array<{ company: string }> = [];
    let company = this.form.get('company').value;
    filter.push({ company });

    this.filterSubject.next(filter);
  }

}
