import { DataSource } from "@angular/cdk/table";
import { ModulesPool, ModulesPoolPage } from "./modules-pool";
import { BehaviorSubject, Observable, of } from "rxjs";
import { PagingAttributes, Pagination } from "app/shared/pagination";
import { ModulesPoolService } from "../modules-pool.service";
import { CollectionViewer } from "@angular/cdk/collections";
import { catchError, finalize } from "rxjs/operators";

export class ModulespoolDs implements DataSource<ModulesPool> {
    public pagination = new BehaviorSubject<PagingAttributes>(
        new PagingAttributes()
    );

    public subject = new BehaviorSubject<ModulesPool[]>([]);
    public loadingSubject = new BehaviorSubject<boolean>(false);

    constructor(private service: ModulesPoolService) {}

    connect(collectionViewer: CollectionViewer): Observable<ModulesPool[]> {
        return this.subject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.subject.complete();
        this.loadingSubject.complete();
    }

    loadData(page: ModulesPoolPage): void {
        this.loadingSubject.next(true);
        this.service
            .loadData(page)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe((data: Pagination<ModulesPool>) => {
                this.subject.next(data.aData);
                this.pagination.next({
                    iTotalDisplayRecords: data.iTotalDisplayRecords,
                    iTotalRecords: data.iTotalRecords
                });
            });
    }
}
