import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EmpmodulepoolService } from '../../empmodulepool.service';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
  animations: fuseAnimations
})
export class ProjectComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['title', 'type', 'need_assesment', 'assesment_parameter', 'assesment_duration', 'score', 'status'];
  id: string;
  projectList: any[] = [];
  unsubscribeAll = new Subject();

  constructor(
    private route: ActivatedRoute,
    public router : Router,
    private service:EmpmodulepoolService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(observer => {
      this.id = observer.id;
    });    

    this.loadData();
  }

  loadData(): void{
    this.service.loadProject(this.id).subscribe(response => {
      console.log(response);
      this.projectList = response;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }


}
