import { ModulesPoolPracticeTest } from 'app/main/modules-pool/model/modules-pool';

export class Modulespool {
  id:number;
  description:string;
  pool_name:string;
  bu:string;
  country:string;
  pool_type:string;
  assesment_parameter:string;
  post_parameter:string;
  practical_test_list?:ModulesPoolPracticeTest[];
  pre_test_id:string;
  post_test_id:string;
}
