export class Account {
  id: number;
  nik: string;
  type: string;
  status: string;
  name: string;
}

export class AccountsPage {
  limit = 20;
  offset = 0;
  search ? = '';
  company ? = '';
}

export const ACCOUNTS_STATUS = ['active', 'inactive'];
export const ACCOUNTS_TYPES = ['panelis', 'participant', 'admin'];