import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyTrainingCalendarComponent } from './my-training-calendar.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { RouterModule, Routes } from '@angular/router';
import {
    CalendarModule as AngularCalendarModule,
    DateAdapter,
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ColorPickerModule } from 'ngx-color-picker';
import { FuseConfirmDialogModule } from '@fuse/components';
import { MyTrainingCalendarService } from './my-training-calendar.service';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule, MatProgressBarModule, MatProgressSpinnerModule, MatSelect, MatSelectModule } from '@angular/material';

const routes: Routes = [
    {
        path: 'my-calendar',
        component: MyTrainingCalendarComponent,
    },
];

@NgModule({
    declarations: [MyTrainingCalendarComponent],
    imports: [
        FuseSharedModule,
        RouterModule.forChild(routes),
        MatButtonModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatTooltipModule,
        MatListModule,
        AngularCalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        ColorPickerModule,
        MatDividerModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatProgressBarModule
    ],
    providers: [MyTrainingCalendarService],
    exports: [RouterModule],
    entryComponents: [],

})
export class MyTrainingCalendarModule { }
