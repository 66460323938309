import { Component, ViewChild, ElementRef, AfterViewInit, ViewEncapsulation, OnInit, Renderer2 } from '@angular/core';
import { MyTrainingCalendarService } from '../../../main/my-training-calendar/my-training-calendar.service';
import { MyTrainingCalendar } from '../../../main/my-training-calendar/my-training-calendar.model';
import {
    startOfDay,
    format,
} from 'date-fns';
import { Subject, Observable } from 'rxjs';
import {
    CalendarEvent,
} from 'angular-calendar';
import { finalize, map } from 'rxjs/operators';
import { AppSettings } from 'app/app.settings';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { DialogTrainingDetailComponent } from './dialog-training-detail/dialog-training-detail.component';
import { MatPaginator, MatDialog } from '@angular/material';
import {
    CommonDialog,
    DialogEvent,
} from 'app/shared/component/dialog/common-dialog/common-dialog';
import { ApiResponse } from 'app/shared/api-response';

@Component({
    selector: 'quick-panel',
    templateUrl: './quick-panel.component.html',
    styleUrls: ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent implements AfterViewInit, OnInit {
    date: Date;
    notes: any[];
    settings: any;

    @ViewChild('calendar', { static: false }) calendar: ElementRef;
    view: string;
    viewDate: Date;
    events: Observable<CalendarEvent[]>;
    selectedDay: any;
    apiEndpoint: string;
    private appSettings = new AppSettings();
    isLoading = true;
    formattedDate: string;
    position = 0;
    listDataModuleUpcoming = [];
    listDataPublicModule = [];
    listDataHistoriModule = [];


    /**
     * Constructor
     */
    constructor(
        private _calendarService: MyTrainingCalendarService,
        private _httpClient: HttpClient,
        private datePipe: DatePipe,
        public dialog: MatDialog,
        private renderer: Renderer2
    ) {
        // Set the defaults
        this.date = new Date();
        this.settings = {
            notify: true,
            cloud: false,
            retro: true
        };
        this.view = 'month';
        this.viewDate = new Date();
        this.selectedDay = { date: startOfDay(new Date()) };
        this.apiEndpoint = this.appSettings.getApiEndpoint('modulesPool');
        this.formattedDate = this.datePipe.transform(this.viewDate, 'MMMM yyyy');
    }

    ngOnInit(): void {
        const dt = new Date();
    }

    async ngAfterViewInit() {
        setTimeout(() => {
            this.now();
        }, 3000);
        this.getModuleUpComing();
        this.getPublicTraining();
        this.getHistoriTraining();
    }


    async generateCalendar() {
        this.calendar.nativeElement.innerHTML = '';
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        daysOfWeek.forEach(day => {
            const dayHeader = document.createElement('div');
            dayHeader.textContent = day;
            dayHeader.style.fontWeight = 'bold';
            this.calendar.nativeElement.appendChild(dayHeader);
        });

        const currentDate = this.viewDate;
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        const startDay = firstDayOfMonth.getDay();
        const daysInMonth = lastDayOfMonth.getDate();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();

        for (let i = 0; i < startDay; i++) {
            const emptyCell = document.createElement('div');
            this.calendar.nativeElement.appendChild(emptyCell);
        }

        for (let i = 1; i <= daysInMonth; i++) {
            const dayDiv = document.createElement('div');
            dayDiv.textContent = i.toString();
            dayDiv.dataset.date = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(i).padStart(2, '0')}`;
            this.calendar.nativeElement.appendChild(dayDiv);

            const today = new Date();
            if (year === today.getFullYear() && month === today.getMonth() && i === today.getDate()) {
                this.renderer.setStyle(dayDiv, 'background-color', '#8ed9a680');
            }
        }



    }

    now() {
        this.viewDate = new Date();
        this.generateCalendar();
        this.getEvents();
    }

    goToPreviousMonth() {
        this.viewDate.setMonth(this.viewDate.getMonth() - 1);
        this.generateCalendar();
        this.getEvents();
    }

    goToNextMonth() {
        this.viewDate.setMonth(this.viewDate.getMonth() + 1);
        this.generateCalendar();
        this.getEvents();
    }


    getEvents() {
        const month = format(this.viewDate, 'MM').toString();
        const year = format(this.viewDate, 'YYYY');
        this.formattedDate = this.datePipe.transform(this.viewDate, 'MMMM yyyy');

        const params = new HttpParams()
            .set('action', 'getMyCalendarData')
            .set('year', year)
            .set('month', month);

        this._httpClient
            .get<any>(
                this.apiEndpoint, {
                params: params,
            }
            )
            .pipe(
                map((data) => data),
                finalize(() =>
                    setTimeout(() => (this.isLoading = false), 1000)
                )
            )
            .subscribe(
                (data) => {

                    let date_event = [];
                    let current = new Date();
                    for (let i = 0; i < data.length; i++) {
                        let start = new Date(data[i].start);
                        let end = new Date(data[i].end);
                        let range_date = this.getDates(start, end);
                        for (let j = 0; j < range_date.length; j++) {
                            this.createEvent(range_date[j], data[i].title, (current >= range_date[j]) ? ((current == range_date[j]) ? '#8ed9a680' : '#d98e8e80') : '#8ecfd980')
                            let events = [];
                            events.push(data[i].title)
                            date_event.push({
                                date: range_date[j],
                                event: events,
                                color: (current >= range_date[j]) ? '#d98e8e80' : '#8ecfd980'
                            });
                        }
                    }
                },
                (error) => {

                }
            );
    }

    getDates(startDate, endDate) {
        const dates = [];
        let currentDate = new Date(startDate);
        const addDays = function (days) {
            const date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        };
        while (currentDate <= endDate) {
            dates.push(currentDate);
            currentDate = addDays.call(currentDate, 1);
        }
        return dates;
    }

    createEvent(date, description, color) {
        const bullet = document.createElement('span');
        bullet.classList.add('dot');

        const dayDiv = Array.from(this.calendar.nativeElement.children).find((div: HTMLElement) => div.innerText === String(date.getDate()));
        if (dayDiv) {
            this.renderer.setStyle(dayDiv, 'background-color', color);
            // this.renderer.appendChild(dayDiv, bullet);
        }
    }

    getModuleUpComing() {
        const params = new HttpParams()
            .set('action', 'getModuleUpComing');
        this.listDataModuleUpcoming = [];

        this._httpClient
            .get<any>(
                this.apiEndpoint, {
                params: params,
            }
            )
            .pipe(
                map((data) => data),
                finalize(() =>
                    setTimeout(() => (this.isLoading = false), 1000)
                )
            )
            .subscribe(
                (data) => {
                    this.listDataModuleUpcoming = data;

                },
                (error) => {

                }
            );
    }

    getPublicTraining() {
        const params = new HttpParams()
            .set('action', 'getPublicTraining');
        this.listDataPublicModule = [];

        this._httpClient
            .get<any>(
                this.apiEndpoint, {
                params: params,
            }
            )
            .pipe(
                map((data) => data),
                finalize(() =>
                    setTimeout(() => (this.isLoading = false), 1000)
                )
            )
            .subscribe(
                (data) => {
                    this.listDataPublicModule = data;

                },
                (error) => {

                }
            );
    }

    getHistoriTraining() {
        const params = new HttpParams()
            .set('action', 'getHistoriTraining');
        this.listDataHistoriModule = [];

        this._httpClient
            .get<any>(
                this.apiEndpoint, {
                params: params,
            }
            )
            .pipe(
                map((data) => data),
                finalize(() =>
                    setTimeout(() => (this.isLoading = false), 1000)
                )
            )
            .subscribe(
                (data) => {
                    this.listDataHistoriModule = data;

                },
                (error) => {

                }
            );
    }

    detailTraining(ref: any): void {
        const dialogRef = this.dialog.open(DialogTrainingDetailComponent, {
            data: ref,
            panelClass: 'elearning-dialog',
        });

        dialogRef
            .afterClosed()
            .subscribe((result: DialogEvent<ApiResponse>) => {
                this.ngAfterViewInit();
            });
    }

}

