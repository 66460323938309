import {
    Component,
    OnInit,
    ViewEncapsulation,
    QueryList,
    ViewChildren,
    ChangeDetectorRef,
    AfterViewInit,
    HostListener,
    ElementRef,
    Input,
    OnDestroy,
    ViewChild,
    Renderer2
} from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ModulessectionDs } from '../model/modulessection-ds';
import { Modulessection } from '../model/modulessection';
import { SelectionModel } from '@angular/cdk/collections';
import { ModulessectionService } from '../modulessection.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ModulesService } from 'app/main/modules/modules.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { AppSettings } from 'app/app.settings';
import { DomSanitizer } from '@angular/platform-browser';
import { Modules } from '../../modules/model/modules';
import { CommonDialog } from 'app/shared/component/dialog/common-dialog/common-dialog';
import { CommonDialogService } from 'app/shared/component/dialog/common-dialog/common-dialog.service';
import { MatPaginator, MatSnackBar } from '@angular/material';
import { EmpmodulepoolService } from '../../empmodulepool/empmodulepool.service';
import { getElement } from '@amcharts/amcharts4/core';
import { VgAPI } from 'videogular2/compiled/core';

declare var YT: any;

@Component({
    selector: 'app-startsection',
    templateUrl: './startsection.component.html',
    styleUrls: ['./startsection.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class StartsectionComponent implements OnInit, AfterViewInit {
    animationDirection: 'left' | 'right' | 'none';
    courseStepContent: any;
    currentStep: number;
    appSettings = new AppSettings();

    @ViewChildren(FusePerfectScrollbarDirective)
    fuseScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;

    dataSource: ModulessectionDs;
    selection = new SelectionModel<Modulessection>(true, []);

    searchTextSubject = new Subject<any>();
    src = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';
    id: number;
    module_title: string;
    description: string;
    module_type: string;
    timestamp: string;
    totalSteps: number;
    section: Modulessection[];
    doc: string;
    idModules: string;
    elModulePoolListId: any;
    empModulePoolId: any;
    messageAssessment = '';
    element: HTMLElement;
    file: any;
    csrfToken = '';
    @HostListener('window:message', ['$event'])
    // tslint:disable-next-line:typedef

    //     @ViewChild('target', {static: true}) target: ElementRef;

    //   // See options: https://videojs.com/guides/options
    //   options: {
    //     controls: boolean,
    //       autoplay: boolean,
    //       sources: {
    //           src: string,
    //           type: string,
    //       }[],
    //   };

    //   player: videojs.Player;
    api: VgAPI;
    videoDuration: number = 10;
    currentTime: number = 0;
    currentTimeMinute: String = '0:0';
    videoDurationMinute: String = '0:0';
    sectionComplete = false;
    private player: any;

    videoId: string = '';
    isPlaying = false;
    onPostMessage(event) {
        if (event.origin !== 'https://hcportal.nabatisnack.co.id:8087') {
            return false;
        }
        const message = JSON.parse(event.data);
        this.messageAssessment = message.message;
        if (message.type === 'finish') {
            this.element = document.getElementById('btngoto') as HTMLElement;
            console.log(this.element);
            if (this.element) {
                this.element.setAttribute('disabled', 'true');
            }
        }
    }

    constructor(
        private route: ActivatedRoute,
        public service: ModulessectionService,
        public serviceModules: ModulesService,
        private splaceScreen: FuseSplashScreenService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseSidebarService: FuseSidebarService,
        public router: Router,
        private dom: DomSanitizer,
        public commonDialogService: CommonDialogService,
        public serviceFile: EmpmodulepoolService,
        public snackBar: MatSnackBar,
        private renderer: Renderer2,
        private el: ElementRef
    ) {
        this.animationDirection = 'none';
        this.currentStep = 0;
        this.doc = this.appSettings.getSettings().documentURL;
    }

    ngOnInit(): void {
        this.route.params.subscribe((observer) => {
            this.id = observer.id;
            this.idModules = observer.idModules;
            this.empModulePoolId = observer.empModulePoolId;
            this.elModulePoolListId = observer.elModulePoolListId;
        });
        this.dataSource = new ModulessectionDs(this.service);

        setTimeout(() => {
            // this.splaceScreen.hide();
        }, 30000);
        this.loadData();
    }

    ngAfterViewInit(): void {
        this.courseStepContent = this.fuseScrollbarDirectives.find(
            (fuseScrollbarDirective) => {
                return (
                    fuseScrollbarDirective.elementRef.nativeElement.id ===
                    'course-step-content'
                );
            }
        );
    }

    gotoStep(step): void {
        // this.serviceModules
        //     .getTokens()
        //     .subscribe((result) => {
        this.csrfToken = 'dhfdsy983yr98uoihh';
        // Decide the animation direction
        this.animationDirection = this.currentStep < step ? 'left' : 'right';
        this.videoDuration = 10;
        this.currentTime = 0;
        // Run change detection so the change
        // in the animation direction registered
        this._changeDetectorRef.detectChanges();

        var url = this.appSettings.getApiEndpoint('files');
        this.file = null;
        var file_url =
            url +
            '?action=getFile&token=' +
            this.csrfToken +
            '&file_name=' +
            this.section[step].link_file;
        if (
            this.section[step].section_type === 'video' ||
            this.section[step].section_type === 'picture' ||
            this.section[step].section_type === 'e-book' ||
            this.section[step].section_type === 'audio'
        ) {
            if (this.section[step].section_type === 'e-book') {
                file_url += '#toolbar=0';
                this.file = file_url;
            } else {
                this.file = this.urlSanitizer(file_url);
            }
        } else if (this.section[step].section_type === 'embed') {
            this.file = this.urlSanitizer(file_url);
        } else if (this.section[step].section_type === 'youtube') {

            this.videoId = this.section[step].link_file;
            this.isPlaying = false;

            if (this.player) {
                this.player.destroy();
                const ytPlayerElement = this.el.nativeElement.querySelector('#ytplayer');
                if (ytPlayerElement) {
                    this.renderer.removeChild(ytPlayerElement.parentNode, ytPlayerElement);
                }
            }


            this.loadYouTubeAPI();
        } else if (this.section[step].section_type != 'content') {
            this.file = this.createObjectURL(file_url);
        }

        setTimeout(() => {
            var vidio = document.getElementsByTagName('video'); // Get the first <h1> element in the document
            for (let i = 0; i < vidio.length; i++) {
                var controlsList = document.createAttribute('controlsList'); // Create a "class" attribute
                controlsList.value = 'nodownload'; // Set the value of the class attribute
                vidio[i].setAttributeNode(controlsList);

                var controlsList = document.createAttribute('oncontextmenu'); // Create a "class" attribute
                controlsList.value = 'return false;'; // Set the value of the class attribute
                vidio[i].setAttributeNode(controlsList);
            }
        }, 200);

        this.currentStep = step;
        // });

        // Add the class attribute to <h1>

        // Set the current step
    }

    createObjectURL(url) {
        if ((window as any).webkitURL) {
            return (window as any).webkitURL.createObjectURL(url);
        } else if ((window as any).URL && (window as any).URL.createObjectURL) {
            return (window as any).URL.createObjectURL(url);
        } else {
            return null;
        }
    }
    /**
     * Go to next step
     */
    gotoNextStep(): void {
        if (this.currentStep === this.totalSteps - 1) {
            return;
        }

        // Set the animation direction
        // this.animationDirection = 'left';

        // Run change detection so the change
        // in the animation direction registered
        this._changeDetectorRef.detectChanges();

        // Increase the current step
        this.currentStep++;
        this.gotoStep(this.currentStep);
    }

    /**
     * Go to previous step
     */
    gotoPreviousStep(): void {
        if (this.currentStep === 0) {
            return;
        }

        // Set the animation direction
        // this.animationDirection = 'right';

        // Run change detection so the change
        // in the animation direction registered
        this._changeDetectorRef.detectChanges();

        // Decrease the current step
        this.currentStep--;
        this.gotoStep(this.currentStep);
    }

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }

    loadData(): void {
        this.serviceModules
            .getModulesDetail(
                this.id,
                this.empModulePoolId,
                this.elModulePoolListId
            )
            .subscribe((result) => {
                this.module_title = result.module_title;
                this.module_type = result.module_type;
                this.description = result.description;
                this.timestamp = result.timestamp;
                this.totalSteps = result.totalSection;
                for (let i = 0; i < result.section.length; i++) {
                    if (result.section[i].section_type === 'embed') {
                        result.section[i].link_file = this.urlSanitizer(
                            result.section[i].link_file
                        );
                    }
                }

                this.section = result.section;
                this.gotoStep(0);
            });
    }

    modulesPoolList(): void {
        this.router.navigate([
            `dashboard/${this.idModules}/${this.empModulePoolId}`,
        ]);
    }

    urlSanitizer(url) {
        if (!url) {
            return '';
        }
        if (url) {
            url = url.replace('watch?v=', 'embed/');
        }

        return this.dom.bypassSecurityTrustResourceUrl(url);
    }

    submitModuleCheck(): void {
        var data = {
            el_module_pool_list_id: this.elModulePoolListId,
            emp_module_pool_id: this.empModulePoolId,
        };

        const dialog = new CommonDialog({
            title: 'Check Module',
            message: 'Are you sure want to check this item ?',
            isCancelable: true,
            textButtonCancel: 'CANCEL',
        });

        this.commonDialogService.open(dialog).subscribe((val) => {
            if (val.isSubmit()) {
                this.serviceModules.submitModuleCheck(data).subscribe((res) => {
                    this.router.navigate([
                        `dashboard/${this.idModules}/${this.empModulePoolId}`,
                    ]);
                    this.snackBar.open(res.message, 'OK', {
                        duration: 90000,
                        horizontalPosition: 'end',
                        verticalPosition: 'top',
                        panelClass: ['snackbar-info'],
                    });
                });
            }
            if (val.isCancel() || val.isDestroy()) {
                this.snackBar.open('Request Cancelled', 'OK', {
                    duration: 90000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                    panelClass: ['snackbar-info'],
                });
            }
        });
    }

    getFile(step, token) { }

    getCookie(name) {
        if (!document.cookie) {
            return null;
        }

        const xsrfCookies = document.cookie
            .split(';')
            .map((c) => c.trim())
            .filter((c) => c.startsWith(name + '='));

        if (xsrfCookies.length === 0) {
            return null;
        }
        return decodeURIComponent(xsrfCookies[0].split('=')[1]);
    }

    onPlayerReady(api: VgAPI) {
        this.api = api;
        if (this.api) {
            console.log('API successfully assigned');
        }
        // // Gunakan API Videogular untuk mendapatkan informasi durasi
        // this.api.getDefaultMedia().subscriptions.loadedMetadata.subscribe(() => {
        //     this.videoDuration = this.api.getDefaultMedia().duration;
        //     console.log('Video Duration:', this.videoDuration);
        // });

        // // Dapatkan waktu saat ini saat video diputar
        this.api.getDefaultMedia().subscriptions.timeUpdate.subscribe(() => {
            this.currentTime = this.api.getDefaultMedia().currentTime;
            console.log('Current Time:', this.currentTime);
        });
    }

    // Event handler ketika metadata video telah dimuat
    onMetadataLoaded(event: Event) {
        const video: HTMLVideoElement = event.target as HTMLVideoElement;
        this.videoDuration = video.duration;
    }

    // Event handler untuk pembaruan waktu video
    onTimeUpdate(event: Event) {
        const video: HTMLVideoElement = event.target as HTMLVideoElement;
        this.currentTime = video.currentTime;

        const minutes = Math.floor(this.currentTime / 60);
        const seconds = Math.floor(this.currentTime % 60);

        if (this.videoDuration - this.currentTime <= 5) {
            this.section[this.currentStep].is_complete = true;
            console.log('Video tinggal 5 detik lagi!');
        }

        this.currentTimeMinute = `${minutes}:${seconds
            .toString()
            .padStart(2, '0')}`;
    }

    loadYouTubeAPI(): void {
        const videoTimerElement = this.el.nativeElement.querySelector('.video-timer');
        const newDiv = this.renderer.createElement('div');
        this.renderer.setAttribute(newDiv, 'id', 'ytplayer');
        this.renderer.appendChild(videoTimerElement.parentNode, newDiv);

        if (!(window as any).YT) {
            const scriptTag = document.createElement('script');
            scriptTag.src = 'https://www.youtube.com/player_api';
            const firstScriptTag = document.getElementsByTagName('script')[0];
            if (firstScriptTag.parentNode) {
                firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);
            }

            (window as any).onYouTubePlayerAPIReady = () => this.createPlayer();
        } else {
            this.createPlayer();
        }
    }

    createPlayer(): void {
        this.player = new YT.Player('ytplayer', {
            // height: '360',
            // width: '640',
            videoId: this.videoId,
            playerVars: {
                controls: 0,
                modestbranding: 0,
                rel: 0,
                showinfo: 0,
                iv_load_policy: 3,
                fs: 1,
                autoplay: 1,
            },
            events: {
                onReady: this.onPlayerReadyYt.bind(this),
                onStateChange: this.onPlayerStateChange.bind(this),
            },
        });

        this.monitorPlayerState();
    }

    onPlayerReadyYt(event: any) {
        this.player = event.target;
    }

    onPlayerStateChange(event: any) {
        // Ubah status isPlaying berdasarkan state YouTube Player
        if (event.data === (window as any).YT.PlayerState.PLAYING) {
            this.isPlaying = true;
        } else if (
            event.data === (window as any).YT.PlayerState.PAUSED ||
            event.data === (window as any).YT.PlayerState.ENDED
        ) {
            this.isPlaying = false;
        }
    }

    togglePlayPause() {
        if (!this.player || typeof this.player.getPlayerState !== 'function') {
            this.player.destroy();
            const ytPlayerElement = this.el.nativeElement.querySelector('#ytplayer');
            if (ytPlayerElement) {
                this.renderer.removeChild(ytPlayerElement.parentNode, ytPlayerElement);
            }
            this.loadYouTubeAPI();
            console.error('Player is not ready or method is not available');
            return;
        }

        const playerState = this.player.getPlayerState();
        if (playerState === (window as any).YT.PlayerState.PLAYING) {
            this.player.pauseVideo();
        } else {
            this.player.playVideo();
        }
    }

    goFullscreen(): void {
        const iframe = document.getElementById('ytplayer') as HTMLDivElement;
        if (iframe.requestFullscreen) {
            iframe.requestFullscreen();
        } else if ((iframe as any).webkitRequestFullscreen) {
            (iframe as any).webkitRequestFullscreen(); // For Safari
        } else if ((iframe as any).msRequestFullscreen) {
            (iframe as any).msRequestFullscreen(); // For IE/Edge
        }
    }

    monitorPlayerState(): void {
        setInterval(() => {
            if (
                this.player &&
                this.player.getDuration &&
                this.player.getCurrentTime
            ) {
                const duration = this.player.getDuration(); // Get the video duration in seconds
                const currentTime = this.player.getCurrentTime(); // Get the current playback time in seconds

                const durationMinutes = Math.floor(duration / 60);
                const durationSeconds = Math.floor(duration % 60);
                const currentMinutes = Math.floor(currentTime / 60);
                const currentSeconds = Math.floor(currentTime % 60);
                this.videoDuration = duration;
                this.currentTime = currentTime;

                if (duration - currentTime <= 5) {
                    this.section[this.currentStep].is_complete = true;
                    console.log('Video tinggal 5 detik lagi!');
                }

                this.videoDurationMinute = `${durationMinutes}:${durationSeconds
                    .toString()
                    .padStart(2, '0')}`;
                this.currentTimeMinute = `${currentMinutes}:${currentSeconds
                    .toString()
                    .padStart(2, '0')}`;
            }
        }, 1000); // Update every second
    }

    isNextButtonDisabled(): boolean {
        const currentSection = this.section[this.currentStep];
        return (
            (currentSection.section_type === 'video' ||
                currentSection.section_type === 'youtube') &&
            currentSection.is_complete
        );
    }

    isDoneButtonDisabled(): boolean {
        const currentSection = this.section[this.currentStep];
        return !(currentSection.section_type === 'video' ||
            currentSection.section_type === 'youtube'
            ? currentSection.is_complete
            : true);
    }
}
