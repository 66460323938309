import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { PracticalTest } from 'app/main/empmodulepool/model/empmodulepool';
import { ModulesService } from 'app/main/modules/modules.service';
import { EmpmodulepoolService } from 'app/main/empmodulepool/empmodulepool.service';
import { fuseAnimations } from '@fuse/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject } from 'rxjs';
import { CommonDialog } from 'app/shared/component/dialog/common-dialog/common-dialog';
import { CommonDialogService } from 'app/shared/component/dialog/common-dialog/common-dialog.service';

@Component({
  selector: 'app-practicaltest',
  templateUrl: './practicaltest.component.html',
  styleUrls: ['./practicaltest.component.scss'],
  animations: fuseAnimations,
})
export class PracticaltestComponent implements OnInit {
  toggleDelete = false;
  selection = new SelectionModel<PracticalTest>(true, []);
  deleteToggle = new BehaviorSubject<boolean>(this.toggleDelete);
  practicalTestList: PracticalTest[];
  displayedColumns = ["select", "no", "name", "periode", "isCrewCheck", "dateChecked", "score" ];

  constructor(
    public dialogRef: MatDialogRef<PracticalTest>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private serviceEmpModulesPool: EmpmodulepoolService,
    public commonDialogService : CommonDialogService,
    public snackBar : MatSnackBar
  ) { 
  }

  ngOnInit() {
    this.loadData();
  }

  loadData():void{
    this.serviceEmpModulesPool.loadPracticalTest(this.data.id).subscribe(val => {
      this.practicalTestList = val;
    });
  }

  masterToggle():void{
    this.isAllSelected()
    ? this.selection.clear() : this.practicalTestList.forEach(
      row => this.selection.select(row)
    );
  }

  isAllSelected():boolean {
    const numSelected = this.selection.selected.length;
    const numRows = Number(this.practicalTestList.length);
    
    return numSelected === numRows;
  }

  submitCheck():void{
    const dialog = new CommonDialog({
      title: "Practical Check",
      message: "Are you sure want to check this item ?",
      isCancelable: true,
      textButtonCancel: "CANCEL"
    });
    
    this.commonDialogService.open(dialog).subscribe(val => {
      if (val.isSubmit()) {
        this.serviceEmpModulesPool.updateCrewCheck(this.selection.selected).subscribe(val => {
          this.loadData();
          this.snackBar.open(val.message, "OK", {
            duration: 90000,
            horizontalPosition: "end",
            verticalPosition: "top",
            panelClass: ["snackbar-info"]
          });
        });
      }
      if (val.isCancel() || val.isDestroy()) {
        this.snackBar.open("Request Cancelled", "OK", {
          duration: 90000,
          horizontalPosition: "end",
          verticalPosition: "top",
          panelClass: ["snackbar-info"]
        });
      }
    })

  }
}
