import { Injectable } from '@angular/core';
import { AppSettings } from 'app/app.settings';
import {
    HttpClient,
    HttpParams,
    HttpErrorResponse,
} from '@angular/common/http';
import { StorageService } from 'app/shared/storage.service';

import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { Pagination } from 'app/shared/pagination';
import {
    ModulesPool,
    ModulesPoolPage,
    ModulesSearch,
} from './model/modules-pool';
import { ApiResponse } from 'app/shared/api-response';
import {
    References,
    MainCategory,
    Category,
    MainActivity,
    Activity,
    SkillActivity,
    SkillIndicatorPool,
    ReferenceType,
} from 'app/shared/interfaces/references';

import { map, catchError } from 'rxjs/operators';
import { OnlineTestPool } from 'app/shared/interfaces/online-test-pool';
import { DashboardData } from '../dashboard-admin/dashboard-admin';

@Injectable({
    providedIn: 'root',
})
export class ModulesPoolService {
    private appSettings = new AppSettings();
    company: string;
    apiEndpoint: string;
    apiEndpointMatrixSkill: string;

    constructor(private http: HttpClient, private storage: StorageService) {
        this.company = this.storage.get('empSite');
        this.apiEndpoint = this.appSettings.getApiEndpoint('modulesPool');
        this.apiEndpointMatrixSkill = this.appSettings.getApiEndpoint(
            'matrixskill'
        );
    }

    loadData(page: ModulesPoolPage): Observable<Pagination<ModulesPool>> {
        let httpParams = new HttpParams();

        httpParams = httpParams.append('action', 'viewData');
        Object.keys(page).forEach((item) => {
            // if (item === 'company') {
            //     httpParams = httpParams.append(item, this.company);
            // } else {
                httpParams = httpParams.append(item, page[item]);
            // }
        });

        return this.http.get<Pagination<ModulesPool>>(this.apiEndpoint, {
            params: httpParams,
        });
    }

    deleteData(params: any): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(this.apiEndpoint, {
            action: 'delete',
            data: params,
        });
    }

    createData(modulesPool: any): Observable<ApiResponse> {
        return this.http
            .post<ApiResponse>(this.apiEndpoint, {
                action: 'create',
                data: modulesPool,
            })
            .pipe(catchError((error) => this.handleError(error)));
    }

    updateData(modulesPool: any): Observable<ApiResponse> {
        return this.http
            .post<ApiResponse>(this.apiEndpoint, {
                action: 'update',
                data: modulesPool,
            })
            .pipe(catchError((error) => this.handleError(error)));
    }

    loadCompany(): Observable<References[]> {
        const nik = this.storage.get('username');
        let httpParams = new HttpParams();
        httpParams = httpParams
            .append('action', 'loadCompany')
            .append('nik', nik);
        return this.http.get<References[]>(this.apiEndpoint, {
            params: httpParams,
        });
    }

    loadQuestionPool(
        company: string,
        search?: string
    ): Observable<OnlineTestPool[]> {
        const nik = this.storage.get('username');
        let httpParams = new HttpParams();
        httpParams = httpParams
            .append('action', 'loadQuestionPool')
            .append('nik', nik)
            .append('company', company)
            .append('search', search);

        return this.http.get<OnlineTestPool[]>(this.apiEndpoint, {
            params: httpParams,
        });
    }

    searchModules(search?: string, ref?: string): Observable<ModulesSearch[]> {
        const nik = this.storage.get('username');
        let httpParams = new HttpParams();
        httpParams = httpParams
            .append('action', 'searchModules')
            .append('nik', nik)
            .append('search', search)
            .append('ref', ref);

        return this.http.get<ModulesSearch[]>(this.apiEndpoint, {
            params: httpParams,
        });
    }

    getDetail(id: any): Observable<any> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('action', 'getDetail').append('id', id);

        return this.http.get<any>(this.apiEndpoint, {
            params: httpParams,
        });
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                    `body was: ${error.error}`
            );
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }

    loadMainCategory(): Observable<MainCategory[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('action', 'getMainCategory');
        return this.http.get<MainCategory[]>(this.apiEndpointMatrixSkill, {
            params: httpParams,
        });
    }

    loadCategory(id: string): Observable<Category[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams
            .append('action', 'getCategory')
            .append('id', id);
        return this.http.get<Category[]>(this.apiEndpointMatrixSkill, {
            params: httpParams,
        });
    }

    loadMainActivity(id: string): Observable<MainActivity[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams
            .append('action', 'getMainActivity')
            .append('id', id);
        return this.http.get<MainActivity[]>(this.apiEndpointMatrixSkill, {
            params: httpParams,
        });
    }

    loadActivity(id: string): Observable<Activity[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams
            .append('action', 'getActivity')
            .append('id', id);
        return this.http.get<Activity[]>(this.apiEndpointMatrixSkill, {
            params: httpParams,
        });
    }

    loadSkillActivity(id: string): Observable<SkillActivity[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams
            .append('action', 'getSkillActivity')
            .append('id', id);
        return this.http.get<SkillActivity[]>(this.apiEndpointMatrixSkill, {
            params: httpParams,
        });
    }

    loadSkillIndicator(id: string): Observable<SkillIndicatorPool[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams
            .append('action', 'getSkillIndicator')
            .append('id', id);
        return this.http.get<SkillIndicatorPool[]>(
            this.apiEndpointMatrixSkill,
            {
                params: httpParams,
            }
        );
    }
    
    loadType(): Observable<ReferenceType[]> {
        let nik = this.storage.get('username');
        let httpParams = new HttpParams();
        httpParams = httpParams.append('action', 'getType');
        return this.http.get<ReferenceType[]>(this.apiEndpoint, {
            params: httpParams,
        });
    }

    loadCompetencyCategory(): Observable<References[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('action', 'getCompetencyCategory');
        return this.http.get<References[]>(this.apiEndpoint, {
            params: httpParams,
        });
    }

    loadCompetencies(id: string): Observable<References[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams
            .append('action', 'getCompetencies')
            .append('id', id);
        return this.http.get<References[]>(this.apiEndpoint, {
            params: httpParams,
        });
    }

    loadDashboardAdminData(
        year: string,
        company: string,
        division: string,
        department: string,
    ): Observable<DashboardData> {
        let httpParams = new HttpParams();
        httpParams = httpParams
            .set('action', 'loadDashboardAdminData')
            .set('company', company)
            .set('division', division)
            .set('department', department)
            .set('year', year);
        return this.http.get<DashboardData>(this.apiEndpoint, {
            params: httpParams,
        });
    }

    loadSubArea(id:string):Observable<References[]>{
        let httpParams = new HttpParams();
        httpParams = httpParams
            .append("action", "loadSubArea")
            .append("company", id)
            .append("nik", this.storage.get('username'))
        return this.http.get<References[]>(this.apiEndpoint, {
            params: httpParams
        });
    }

    loadDivision(id:string):Observable<References[]>{
        let httpParams = new HttpParams();
        httpParams = httpParams
            .append("action", "loadDivision")
            .append("company", id)
        return this.http.get<References[]>(this.apiEndpoint, {
            params: httpParams
        });
    }

    loadDepartment(id:string):Observable<References[]>{
        let httpParams = new HttpParams();
        httpParams = httpParams
            .append("action", "loadDepartment")
            .append("divisi", id)
        return this.http.get<References[]>(this.apiEndpoint, {
            params: httpParams
        });
    }

    loadNotMeetAssesment(division:string, department:string, year:string, company:string, status:string):Observable<References[]>{
        let httpParams = new HttpParams();
        httpParams = httpParams
            .append("action", "getEmpAssesment")
            .append("divisi", division)
            .append("department", department)
            .append("status", status)
            .append("company", company)
            .append("year", year)
        return this.http.get<References[]>(this.apiEndpoint, {
            params: httpParams
        });
    }


}
