import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Helper } from 'app/shared/helper';
import { MatDialogRef, MAT_DIALOG_DATA, MatAutocompleteSelectedEvent } from '@angular/material';
import { Modules } from '../model/modules';
import { ModulesService } from '../modules.service';
import { DialogEvent } from 'app/shared/component/dialog/common-dialog/common-dialog';
import { debounceTime, distinctUntilChanged, tap, switchMap, takeUntil, finalize } from 'rxjs/operators';
import { AssesmentQuestionPool } from 'app/shared/assesment-question-pool';
import { AssesmentQuestionPoolService } from 'app/shared/assesment-question-pool.service';

@Component({
  selector: 'app-form-modules',
  templateUrl: './form-modules.component.html',
  styleUrls: ['./form-modules.component.scss']
})
export class FormModulesComponent implements OnInit {
  form : FormGroup;
  isEdit = false;
  unsubscribeAll = new Subject();
  isLoading = false;
  helper = new Helper();
  selectedEmployee: AssesmentQuestionPool;
  modulesType: any =[];
  filteredQuestionPool: AssesmentQuestionPool[] = [];

  constructor(
    public dialogRef: MatDialogRef<FormModulesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Modules,
    private formBuilder: FormBuilder,
    private service: ModulesService,
    private assesmentQuestionPoolService: AssesmentQuestionPoolService
  ) { 
    this.isEdit = this.data?true:false;
  }

  ngOnInit(): void{
    this.service.getModulesType().subscribe(response => {
      this.modulesType = response;
    });

    if (this.isEdit) {
      this.form = this.formBuilder.group({
        id : new FormControl(this.data.id, Validators.required),
        description : new FormControl(this.data.description, Validators.required),
        module_title : new FormControl(this.data.module_title, Validators.required),
        id_type : new FormControl(this.data.id_type, Validators.required),
        is_need_assesment : new FormControl(this.data.is_need_assesment, Validators.required),
        assesment_question_pool_id : new FormControl(this.data.assesment_question_pool_id),
        assesment_parameter : new FormControl(this.data.assesment_parameter),
        assesment_duration : new FormControl(this.data.assesment_duration),
        assesment_question_pool : new FormControl(this.data.assesment_question_pool),
        assesment_question_pool_desc : new FormControl(this.data.assesment_question_pool)
      });
    } else {
      this.form = this.formBuilder.group({
        description : new FormControl('', Validators.required),
        module_title : new FormControl('', Validators.required),
        id_type : new FormControl('', Validators.required),
        is_need_assesment : new FormControl('0', Validators.required),
        assesment_question_pool_id : new FormControl(''),
        assesment_parameter : new FormControl(''),
        assesment_duration : new FormControl(''),
        assesment_question_pool : new FormControl(''),
        assesment_question_pool_desc : new FormControl(''),
      });
    }

    this.initAutoComplete();
  }
  
  onSubmit(): void{
    const payload = {
      description : this.form.value.description,
      module_title : this.form.value.module_title,
      id_type : this.form.value.id_type,
      is_need_assesment : this.form.value.is_need_assesment,
      assesment_parameter : this.form.value.assesment_parameter,
      assesment_duration : this.form.value.assesment_duration,
      assesment_question_pool_id : this.form.value.assesment_question_pool_id,
      assesment_question_pool : this.form.value.assesment_question_pool,
    };

    this.service.createData(payload).subscribe(response =>{
      this.dialogRef.close(new DialogEvent('submit', response));
    });

  }

  onUpdate(): void{
    const payload = {
      id : this.form.value.id,
      description : this.form.value.description,
      module_title : this.form.value.module_title,
      id_type : this.form.value.id_type,
      is_need_assesment : this.form.value.is_need_assesment,
      assesment_parameter : this.form.value.assesment_parameter,
      assesment_duration : this.form.value.assesment_duration,
      assesment_question_pool_id : this.form.value.assesment_question_pool_id,
      assesment_question_pool : this.form.value.assesment_question_pool,
    }

    this.service.updateData(payload).subscribe(response => {
      this.dialogRef.close(new DialogEvent('submit', response));
    })
  }

  displayFn(questionPool: AssesmentQuestionPool): string {
    if (questionPool) {
        return questionPool.description;
    }
  }

  onSelected(event: MatAutocompleteSelectedEvent): void {
    this.form.get('assesment_question_pool_id').setValue(event.option.value.id);
    this.form.get('assesment_question_pool').setValue(event.option.value.description);
  }

  initAutoComplete(): void {
    this.form.get('assesment_question_pool_desc')
        .valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(() => (this.isLoading = true)),
            switchMap(value =>
                this.assesmentQuestionPoolService.searchDataQuestionPool(value).pipe(
                    takeUntil(this.unsubscribeAll),
                    finalize(()=>this.isLoading = false)
                )
            )
        ).subscribe(data => {
            this.filteredQuestionPool = data;
        });

  }
  
}
