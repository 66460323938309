export class AppSettings {
    public settings = {
        appName: "E-Learning",
        appDesc: "NDC application for employee learning",
        appVers: "1.0",

        // development
        // baseURL: "http://192.168.18.10/hcportal/src/api/endpoint/elearning",
        // baseURLEval: "http://192.168.18.10/hcportal/src/api/evaluation",
        // baseURLAssesment: "https://hcportal.nabatisnack.co.id:8087/online_test/api/public/training/",
        // imageURL: "http://hcportal.nabatisnack.co.id/api/empdocument/",
        // url: "https://hcportal.nabatisnack.co.id:8087/elearning_rev/api/",
        // documentURL: "https://hcportal.nabatisnack.co.id:8087/elearning_rev/api/learningdocument/",
        // otestRki: "https://hcportal.nabatisnack.co.id:8087/otest-rki/elearning-test/",
        // otest: "https://hcportal.nabatisnack.co.id:8087/otest/gates/"

        // staging
        // baseURL: "https://staging.nabatisnack.co.id/hcportal/api/endpoint/elearning",
        // baseURLEval: "https://staging.nabatisnack.co.id/hcportal/api/evaluation",
        // baseURLAssesment: "https://hcportal.nabatisnack.co.id:8087/online_test/api/public/training/",
        // imageURL: "http://hcportal.nabatisnack.co.id/api/empdocument/",
        // url: "https://staging.nabatisnack.co.id/hcportal/api/",
        // documentURL: "https://staging.nabatisnack.co.id/hcportal/api/learningdocument/",
        // otestRki: "https://staging.nabatisnack.co.id/otest-rki/elearning-test/",
        // otest: "https://staging.nabatisnack.co.id/otest/gates/"

        // production
        baseURL: "https://hcportal.nabatisnack.co.id/api/endpoint/elearning",
        baseURLEval: "http://localhost/hcportal/src/api/evaluation",
        baseURLAssesment: "https://hcportal.nabatisnack.co.id:8087/online_test/api/public/training/",
        imageURL: "http://hcportal.nabatisnack.co.id/api/empdocument/",
        url: "https://hcportal.nabatisnack.co.id/api/",
        documentURL: "https://hcportal.nabatisnack.co.id/api/learningdocument/",
        otestRki: "https://rkionlinetest.nabatisnack.co.id/elearning-test/",
        otest: "http://learning-etest.nabatisnack.co.id/gates/"



        // baseURL: "https://hcportal.nabatisnack.co.id/api/evaluatanguion",
        // baseURL: "https://staging.nabatisnack.co.id/elearning_rev/api/endpoint/elearning",
        // baseURL: "https://hcportal.nabatisnack.co.id/api/endpoint/elearning",
        // documentURL: "http://localhost/hcportal/src/api/learningdocument/",
        // documentURL: "https://learning.nabatisnack.co.id/learning/"
        // documentURL: "https://learning.nabatisnack.co.id/learning/"
        // url: "https://hcportal.nabatisnack.co.id/api/",
        // documentURL: "https://hcportal.nabatisnack.co.id/api/learningdocument/"

    };

    /**
     * apiEndpoint
     * Register API Endpoint Here
     * @param key string
     */
    private apiEndpoint(key: string): any {
        const endpoint = {
            auth: "/auth",
            accounts: "/accounts",
            learningModules: "/learningmodules",
            modulesPool: "/modulespool",
            empModulePool: "/empmodulespool",
            learningTest: "/learningtest",
            matrixskill: "/matrixskill",
            files: "/files",
        };
        return endpoint[key];
    }

    public getSettings(): any {
        return this.settings;
    }

    /**
     * getApiEndpoint
     * Get Data Endpoint
     * @param key string
     */
    public getApiEndpoint(key: string = null): any {
        if (key !== null) {
            return this.settings.baseURL + this.apiEndpoint(key);
        }
        return this.settings.baseURL;
    }

    /* public getApiEndpointEval(key: string = null): any {
        if (key !== null) {
            return this.settings.baseURLEval + this.apiEndpoint(key);
        }
        return this.settings.baseURL;
    } */

}
