import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ApiResponse } from 'app/shared/api-response';
import { CommonDialog } from 'app/shared/component/dialog/common-dialog/common-dialog';
import { CommonDialogService } from 'app/shared/component/dialog/common-dialog/common-dialog.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";
import { EmpmodulepoolService } from '../../empmodulepool.service';
import { Project } from '../../model/empmodulepool';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {
  form:FormGroup;
  unsubscribeAll = new Subject();
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<ProjectDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Project,
    private formBuilder: FormBuilder,
    private service:EmpmodulepoolService,
    private commonDialogService: CommonDialogService
  ) {

  }

  ngOnInit() {
    let startDate:any = new Date(this.data.start_date);
    let endDate:any = new Date(this.data.end_date);

    this.form = this.formBuilder.group({
      id : new FormControl(this.data.id),
      target : new FormControl(this.data.target, Validators.required),
      baseline : new FormControl(this.data.baseline, Validators.required),
      background : new FormControl(this.data.background, Validators.required),
      improvement : new FormControl(this.data.improvement, Validators.required),
      result : new FormControl(this.data.result, Validators.required),
      end_date : new FormControl(endDate, Validators.required),
      start_date : new FormControl(startDate, Validators.required)
    });

  }

  onSubmit():void{
    let submit: Observable<any>;
    let payload = this.form.value;

    submit = this.service.submitProject(payload);

    submit.subscribe(
        response => {
            this.responseDialog(response, true);
        },
        error => {
            this.responseDialog(error, false);
        }
    );
  }
  
  responseDialog(response: ApiResponse, isBack: boolean): void {
    const dialog = new CommonDialog({
        title: response.title,
        message: response.message
    });

    this.commonDialogService
        .open(dialog)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe(response => {
            if (response.isSubmit() && isBack) {
                
            }
        });
}

}
