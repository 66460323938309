import { ModulesPoolPracticeTest } from 'app/main/modules-pool/model/modules-pool';

export interface References {
    id: any;
    description: string;
}

export interface MainCategory{
    id:number;
    main_category_name:string;
    description:string;
}

export interface Category{
    id:number;
    category_name:string;
}

export interface MainActivity{
    id:number;
    main_activity_name:string;
}

export interface Activity{
    id:number;
    activity_name:string;
}

export interface SkillActivity{
    id:number;
    skill_name:string;
}

export class SkillIndicatorPool{
    id?:number;
    description:string;
    success_criteria:string;
    skill_indicator_id:number;
    constructor(description:string, success_criteria:string, skill_indicator_id:number){
        this.skill_indicator_id = skill_indicator_id;
        this.description = description;
        this.success_criteria = success_criteria;
    }
}

export interface ReferenceType {
    id: any;
    description: any;
}

export class CompetencyPool{
    id?:number;
    start_level:number;
    target_level:number;
    competency_id:number;
    competency:string;
    practicePool:ModulesPoolPracticeTest[];

    constructor(start_level: number, target_level:number, competency_id:number, competency:string) {
        this.start_level = start_level;
        this.target_level = target_level;
        this.competency_id = competency_id;
        this.competency = competency;
    }
}