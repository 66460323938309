import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import { MatPaginator, MatDialog, MatSnackBar } from "@angular/material";
import { Subject, BehaviorSubject, Observable, Subscription } from "rxjs";
import { Helper } from "app/shared/helper";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { CommonDialogService } from "app/shared/component/dialog/common-dialog/common-dialog.service";
import { Router, NavigationEnd } from "@angular/router";
import { ModulesPool } from "./model/modules-pool";
import { ModulespoolDs } from "./model/modules-pool-ds";
import { ModulesPoolService } from "./modules-pool.service";
import { takeUntil, map, finalize } from "rxjs/operators";
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { References } from 'app/shared/interfaces/references';
import { StorageService } from 'app/shared/storage.service';

@Component({
    selector: "app-modulespool",
    templateUrl: "./modules-pool.component.html",
    styleUrls: ["./modules-pool.component.scss"],
    animations: fuseAnimations
})
export class ModulesPoolComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    unsubscribeAll = new Subject();
    toggleDelete = false;
    searchTextSubject = new Subject<any>();
    filterSubject = new Subject<any[]>();

    dataSource: ModulespoolDs;
    deleteToggle = new BehaviorSubject<boolean>(this.toggleDelete);
    helper = new Helper();
    showBack = false;
    titleSubject = new Subject<string>();
    descriptionSubject = new Subject<string>();
    title = "";
    description = "";
    blockComponent = new BehaviorSubject<boolean>(false);
    deleteAction = new BehaviorSubject<boolean>(false);
    form: FormGroup;
    loadingState = {
        company: false,
        subArea: false,
    };
    companies: Observable<References[]>;
    subAreas: Observable<References[]>;
    unsubscribe: Subscription[] = [];

    floatLabelControl = new FormControl('auto');

    constructor(
        private splaceScreen: FuseSplashScreenService,
        public dialog: MatDialog,
        public commonDialogService: CommonDialogService,
        public snackBar: MatSnackBar,
        public service: ModulesPoolService,
        public router: Router,
        private formBuilder: FormBuilder,
        private storage: StorageService
    ) {
        this.form = this.formBuilder.group({
            company: new FormControl(this.storage.get('empSite')),
            subArea: new FormControl('all'),
        });

        const companySub = this.form
            .get('company')
            .valueChanges.subscribe((item) => {
                this.loadSubArea();
            });
            
        this.unsubscribe.push(companySub);
        
        this.router.events
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(nav => {
                if (nav instanceof NavigationEnd) {
                    if (nav.url.includes("transaction")) {
                        this.showBack = true;
                    } else {
                        this.showBack = false;
                    }
                }
            });

        // watch for title change
        this.titleSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(title => {
                this.title = title;
            });
            
        // watch for description change
        this.descriptionSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(desc => {
                this.description = desc;
            });

        this.deleteToggle
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(val => {
                this.toggleDelete = val;
            });
    }

    ngOnInit() {
        // this.splaceScreen.hide();
        this.loadCompany();
        this.loadSubArea();
    }

    search(query: string): void {
        this.searchTextSubject.next(query);
    }

    toUpdateTransaction(ref?: ModulesPool): void {
        this.router.navigate(["modules-pool/transaction/" + ref.id]);
    }

    toCreateTransaction(): void {
        this.router.navigate(["modules-pool/transaction"]);
    }

    backToView(): void {
        this.router.navigate(["modules-pool/view"]);
    }

    onClickDeleteAction(): void {
        this.deleteAction.next(true);
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    block(block: boolean): void {}

    
    loadCompany(): void {
        this.loadingState.company = true;
        this.companies = this.service.loadCompany().pipe(
            map((result) => result),
            finalize(() => (this.loadingState.company = false))
        );
    }

    loadSubArea(): void {
        this.loadingState.subArea = true;
        this.form.get('subArea').setValue('all');
        this.subAreas = this.service
            .loadSubArea(this.form.get('company').value)
            .pipe(
                map((result) => result),
                finalize(() => (this.loadingState.subArea = false))
            );
    }

    loadDataFilter():void{
        let filter : Array<{company: string, subArea: number}> = []; 
        let company = this.form.get('company').value;
        let subArea = this.form.get('subArea').value;
        filter.push({company, subArea});
        
        this.filterSubject.next(filter);      
    }
}
