import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModulessectionComponent } from './modulessection.component';
import { AuthGuard } from 'app/shared/auth/auth.guard';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatProgressBarModule, MatInputModule, MatToolbarModule, MatTableModule, MatPaginatorModule, MatSortModule, MatIconModule, MatProgressSpinnerModule, MatButtonModule, MatDialogModule, MatSelectModule, MatFormFieldModule, MatCheckboxModule, MatSnackBarModule, MatTooltipModule, MatAutocompleteModule, MatTabsModule, MatDividerModule } from '@angular/material';
import { FormModulessectionComponent } from './form-modulessection/form-modulessection.component';
import { StartsectionComponent } from './startsection/startsection.component';
import { FuseSidebarModule } from '@fuse/components';

import {VgCoreModule} from 'videogular2/compiled/core';
import {VgControlsModule} from 'videogular2/compiled/controls';
import {VgOverlayPlayModule} from 'videogular2/compiled/overlay-play';
import {VgBufferingModule} from 'videogular2/compiled/buffering';
import { EditorModule } from '@tinymce/tinymce-angular';


import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MediacontentComponent } from './mediacontent/mediacontent.component';

const routes = [
  {
    path : 'section/:id',
    component : ModulessectionComponent,
    canActivate : [AuthGuard]
  },

  {
    path : 'section_start/:id/:idModules/:empModulePoolId/:elModulePoolListId',
    component : StartsectionComponent,
    canActivate : [AuthGuard]
  }

]

@NgModule({
  declarations: [
    ModulessectionComponent,
    FormModulessectionComponent,
    StartsectionComponent,
    MediacontentComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    MatProgressBarModule,
    MatInputModule,
    MatToolbarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatTabsModule,
    FuseSidebarModule,
    EditorModule,
    MatDividerModule,

    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    NgxExtendedPdfViewerModule
    
  ],
  exports: [
    RouterModule
  ],
  entryComponents:[
    FormModulessectionComponent,
    StartsectionComponent,
    MediacontentComponent
  ]
})
export class ModulessectionModule { }
