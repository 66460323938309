export const locale = {
    lang: 'en',
    data: {
        NAV: {
            APPLICATIONS: 'Home',
            MODULES: 'Training Modules',
            SAMPLE: {
                TITLE: 'Sample',
                BADGE: '25',
            },

            ACCOUNT: {
                TITLE: 'Accounts',
            },

            MODULESTYPE: {
                TITLE: 'Modules Type',
            },

            ELMODULES: {
                TITLE: 'Modules',
            },

            DASHBOARD: {
                TITLE: 'Dashboard',
            },

            MODULESPOOL: {
                TITLE: 'Modules Pool',
            },

            EMP_MODULES_POOL: {
                TITLE: 'Employee Modules Pool',
            },
            TRAINING_CALENDAR: {
                TITLE: 'Training Calendar',
            },
            DASHBOARD_ADMIN: {
                TITLE: 'Dashboard',
            },
        },
    },
};
