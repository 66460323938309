import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from 'app/shared/auth/auth.guard';
import { BannerComponent } from './banner.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatProgressBarModule, MatInputModule, MatToolbarModule, MatTableModule, MatPaginatorModule, MatSortModule, MatIconModule, MatProgressSpinnerModule, MatButtonModule, MatDialogModule, MatSelectModule, MatFormFieldModule, MatCheckboxModule, MatSnackBarModule, MatTooltipModule, MatAutocompleteModule, MatTabsModule, MatRadioModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormBannerComponent } from './form-banner/form-banner.component';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

const routes = [
  {
    path: 'banner',
    component: BannerComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    BannerComponent,
    FormBannerComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    MatProgressBarModule,
    MatInputModule,
    MatToolbarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatAutocompleteModule,
    BrowserAnimationsModule,
    MatRadioModule,
    FormsModule,
    BrowserModule
  ],
  exports: [
    RouterModule
  ],
  entryComponents: [
    FormBannerComponent
  ]
})
export class BannerModule { }
