import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { Empmodulepool, PoolList, Project, PracticalTest } from '../model/empmodulepool';
import { fuseAnimations } from '@fuse/animations';
import { EmpmodulepoolService } from '../empmodulepool.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { UpdatePracticeScoreComponent } from './update-practice-score/update-practice-score.component';
import { MatDialog } from '@angular/material';
import { DialogEvent, CommonDialog } from 'app/shared/component/dialog/common-dialog/common-dialog';
import { ApiResponse } from 'app/shared/api-response';
import { CommonDialogService } from 'app/shared/component/dialog/common-dialog/common-dialog.service';
import { Subject } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { AppSettings } from 'app/app.settings';

@Component({
  selector: 'app-detail-empmodulepool',
  templateUrl: './detail-empmodulepool.component.html',
  styleUrls: ['./detail-empmodulepool.component.scss'],
  animations: fuseAnimations
})
export class DetailEmpmodulepoolComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns: string[] = ['title', 'type', 'need_assesment', 'assesment_parameter', 'assesment_duration', 'score', 'status'];
  displayedHistoryColumns: string[] = ['pool_name', 'pool_type', 'periode', 'progress', 'pre_score', 'post_score'];
  displayedColumnsSkillMatrix: string[] = ['skill_indicator', 'success_criteria'];
  displayedColumnsCompetencies: string[] = ['competency', 'start_level', 'target_level'];
  displayedColumnsProject: string[] = ['project_title', 'baseline', 'start_date', 'end_date', 'path_file_result']
  data: Empmodulepool;

  poolList;
  poolHistoryList;
  skillMatrixList;
  projectList;
  project = [];
  competenciesList: any[] = [];

  id: string;
  unsubscribeAll = new Subject();

  apiEndpoint: string;
  private appSettings = new AppSettings();


  constructor(
    private splaceScreen: FuseSplashScreenService,
    private route: ActivatedRoute,
    private service: EmpmodulepoolService,
    public router: Router,
    public dialog: MatDialog,
    public commonDialogService: CommonDialogService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(observer => {
      this.id = observer.id;
    });

    this.loadData();
    // this.splaceScreen.hide();
  }

  changeTab($event) {
    if ($event.index == 1) {
      this.loadDataHistoryPoolList(this.data.nik);
    }
  }

  async loadData(): Promise<any> {

    return new Promise((resolve, reject) => {
      this.service.loadPoolList(this.id).subscribe(response => {
        this.data = response.empModulePool;
        this.poolList = new MatTableDataSource(response.modulePoolList);
        this.poolList.sort = this.sort;

        this.skillMatrixList = new MatTableDataSource(response.skillIndicatorList);
        this.skillMatrixList.sort = this.sort;
        this.project = response.project;
        this.projectList = new MatTableDataSource(response.project);
        this.projectList.sort = this.sort;

        this.competenciesList = response.competenciesList;

      });
    })

  }

  async loadDataHistoryPoolList(nik): Promise<any> {

    return new Promise((resolve, reject) => {
      this.service.loadHistoryPoolList(nik).subscribe(response => {
        // this.data = response.empModulePool;
        this.poolHistoryList = new MatTableDataSource(response.aData);
        this.poolHistoryList.sort = this.sort;

        // this.skillMatrixList = new MatTableDataSource(response.skillIndicatorList);
        // this.skillMatrixList.sort = this.sort;
        // this.project=response.project;
        // this.projectList = new MatTableDataSource(response.project);
        // this.projectList.sort = this.sort;

        // this.competenciesList = response.competenciesList;
      });
    })

  }

  backToView(): void {
    this.router.navigate(["empmodulepool"]);
  }

  setScore(data: PracticalTest): void {
    const dialogRef = this.dialog.open(UpdatePracticeScoreComponent, {
      data: data,
      panelClass: 'elearning-dialog'
    })

    dialogRef
      .afterClosed()
      .subscribe((result: DialogEvent<ApiResponse>) => {
        if (result instanceof DialogEvent) {
          if (result.isSubmit()) {
            this.responseDialog(result.getData());
          }
        } else {
          this.loadData();
        }

      });
  }

  responseDialog(response: ApiResponse): void {
    const dialog = new CommonDialog({
      title: response.title,
      message: response.message
    });
    this.commonDialogService.open(dialog).subscribe(() => this.loadData());
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  detail(ref: Project): void {
    const dialogRef = this.dialog.open(ProjectDetailComponent, {
      data: ref,
      panelClass: 'elearning-dialog'
    })

    dialogRef
      .afterClosed()
      .subscribe((result: DialogEvent<ApiResponse>) => {
        if (result instanceof DialogEvent) {
          if (result.isSubmit()) {
            this.responseDialog(result.getData());
          }
        } else {
          this.loadData();
        }

      });
  }

  downloadFileProject(item) {
    // path_file_result
    this.apiEndpoint = this.appSettings.getSettings().url;
    window.open(this.apiEndpoint + '/uploadfile/elearning/' + item.path_file_result, '_blank');

  }
}
