import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatPaginator, MatDialog, MatSnackBar } from '@angular/material';
import { PaginationPage } from 'app/shared/pagination';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { UserexternalDs } from './model/userexternal-ds';
import { SelectionModel } from '@angular/cdk/collections';
import { Userexternal } from './model/userexternal';
import { Helper } from 'app/shared/helper';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { CommonDialogService } from 'app/shared/component/dialog/common-dialog/common-dialog.service';
import { Router } from '@angular/router';
import { UserexternalService } from './userexternal.service';
import { finalize, tap, map } from 'rxjs/operators';
import { FormUserexternalComponent } from './form-userexternal/form-userexternal.component';
import { CreateCertificateComponent } from './create-certificate/create-certificate.component';
import { DialogEvent, CommonDialog } from 'app/shared/component/dialog/common-dialog/common-dialog';
import { ApiResponse } from 'app/shared/api-response';
import { References } from 'app/shared/interfaces/references';
import { ModulesPoolService } from '../modules-pool/modules-pool.service';
import { StorageService } from 'app/shared/storage.service';
import { AppSettings } from 'app/app.settings';

@Component({
  selector: 'app-userexternal',
  templateUrl: './userexternal.component.html',
  styleUrls: ['./userexternal.component.scss'],
  animations: fuseAnimations
})
export class UserexternalComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  private appSetting = new AppSettings();

  toggleDelete = false;
  searchTextSubject = new Subject<any>();
  page = new PaginationPage;
  dataSource: UserexternalDs;
  selection = new SelectionModel<Userexternal>(true, []);
  deleteToggle = new BehaviorSubject<boolean>(this.toggleDelete);
  helper = new Helper();
  displayedColumns = ["no", "id_card_number", "name", "phone_number", "email_address", "password"];
  company: string = this.storage.get('empSite');
  subArea: string = 'all';
  loadingState = {
    company: false,
    subArea: false,
  };
  companies: Observable<References[]>;
  subAreas: Observable<References[]>;
  filteredList1;
  load = false;
  constructor(
    private splaceScreen: FuseSplashScreenService,
    public dialog: MatDialog,
    public commonDialogService: CommonDialogService,
    public snackBar: MatSnackBar,
    public service: UserexternalService,
    public _service: ModulesPoolService,
    public router: Router,
    private storage: StorageService,
  ) { }

  ngOnInit() {
    this.dataSource = new UserexternalDs(this.service);

    this.loadCompany();
    this.loadSubArea();

    this.page.company = this.company;
    this.page.subArea = this.subArea;
    // this.splaceScreen.hide();
    this.dataSource.loadData(this.page);
    this.deleteToggle.subscribe(val => {
      if (val) {
        this.displayedColumns = ["select", ...this.displayedColumns];
      } else {
        const idx = this.displayedColumns.indexOf("select");
        if (idx !== -1) {
          this.displayedColumns.splice(idx, 1);
        }
      }
    });
  }

  loadData(): void {
    if (!this.load) {
      this.load = true;
      console.time();
      this.page.offset = (this.paginator.pageIndex) * this.paginator.pageSize;
      this.page.limit = this.paginator.pageSize;
      this.page.company = this.company;
      this.page.subArea = this.subArea;
      this.dataSource.loadData(this.page);
      this.load = false;
      console.timeEnd();
      this.selection.clear();
    }
  }

  ngAfterViewInit(): void {
    this.paginator.page.pipe(tap(() => this.loadData())).subscribe();
    this.searchTextSubject.subscribe(val => {
      setTimeout(() => console.log(val)
        , 1000);
    })
  }

  search(query: string): void {
    this.page.search = query;
    this.dataSource.loadData(this.page);
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear() : this.dataSource.subject.value.forEach(
        row => this.selection.select(row)
      );
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.pagination.value.iTotalDisplayRecords;
    return numSelected === numRows;
  }

  openDialog(ref: Userexternal = null): void {
    const dialogRef = this.dialog.open(FormUserexternalComponent, {
      data: ref,
      panelClass: 'elearning-dialog-half'
    })

    dialogRef
      .afterClosed()
      .subscribe((result: DialogEvent<ApiResponse>) => {
        if (result instanceof DialogEvent) {
          if (result.isSubmit()) {
            this.responseDialog(result.getData());
          }
        } else {
          this.loadData();
        }

      });
  }

  close(): void {
    this.loadData();
  }

  responseDialog(response: ApiResponse): void {
    const dialog = new CommonDialog({
      title: response.title,
      message: response.message
    });
    this.commonDialogService.open(dialog).subscribe(() => this.close());
  }

  deleteAction(): void {
    const dialog = new CommonDialog({
      title: "Delete Employee Modules Pool",
      message: "Are you sure want to delete this item ?",
      isCancelable: true,
      textButtonCancel: "CANCEL"
    });

    this.commonDialogService.open(dialog).subscribe(val => {
      if (val.isSubmit()) {
        const payload = this.helper.buildDeletePayload(
          this.selection.selected
        );
        this.service
          .deleteData(payload)
          .subscribe(response =>
            this.responseDialog(new ApiResponse(response)),
          );
      }
      if (val.isCancel() || val.isDestroy()) {
        this.snackBar.open("Request Cancelled", "OK", {
          duration: 90000,
          horizontalPosition: "end",
          verticalPosition: "top",
          panelClass: ["snackbar-info"]
        });
      }
    })

  }

  detail(ref: Userexternal): void {
    let id: number = ref.id;
    this.router.navigate([`detailuserexternal/${id}`]);
  }

  backToView(): void {
    this.router.navigate(["userexternal"]);
  }

  loadCompany(): void {
    this.loadingState.company = true;
    this.companies = this._service.loadCompany().pipe(
      map((result) => result),
      finalize(() => (this.loadingState.company = false))
    );
  }

  loadSubArea(): void {
    this.loadingState.subArea = true;
    this.subArea = 'all';
    this.subAreas = this._service
      .loadSubArea(this.company)
      .pipe(
        map((result) => result),
        finalize(() => (this.loadingState.subArea = false))
      );
  }

  openDialogCreateCertificate(ref: Userexternal = null): void {
    const dialogRef = this.dialog.open(CreateCertificateComponent, {
      data: ref,
      panelClass: 'elearning-dialog-half'
    })

    dialogRef
      .afterClosed()
      .subscribe((result: DialogEvent<ApiResponse>) => {
        if (result instanceof DialogEvent) {
          if (result.isSubmit()) {
            this.responseDialog(result.getData());
          }
        } else {
          this.loadData();
        }

      });
  }

  retestPost(data): void {
    const dialog = new CommonDialog({
      title: "Retest",
      message: "Are you sure want to retest this item ?",
      isCancelable: true,
      textButtonCancel: "CANCEL"
    });

    this.commonDialogService.open(dialog).subscribe(val => {
      if (val.isSubmit()) {
        const payload = {
          ref_pool_id: data.post_test.post_test_ref_pool_id,
          nik: data.nik,
          id: data.post_test.id,
        };

        this.service.retestPost(payload).subscribe(response => {
          this.responseDialog(new ApiResponse(response));

        });
      }
      if (val.isCancel() || val.isDestroy()) {
        this.snackBar.open("Request Cancelled", "OK", {
          duration: 90000,
          horizontalPosition: "end",
          verticalPosition: "top",
          panelClass: ["snackbar-info"]
        });
      }
    })




  }

  exportData() {

    var offset = 0;
    var limit = 0;
    var company = this.company;
    var subArea = this.subArea;

    window.open(this.appSetting.settings.baseURL + '/empmodulespool/exportDataEmpModulePool' + '/' + offset + '/' + limit + '/' + company + '/' + subArea, "_blank");
  }

  formatDate(dateString) {
    // Membuat array untuk nama bulan dalam bahasa Indonesia
    const monthNames = [
      "Januari", "Februari", "Maret", "April", "Mei", "Juni",
      "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];

    // Memisahkan string tanggal berdasarkan tanda "-"
    const dateParts = dateString.split('-');

    // Ambil bagian tahun, bulan, dan hari
    const year = dateParts[0];
    const month = parseInt(dateParts[1], 10); // Mengubah string menjadi angka
    const day = parseInt(dateParts[2], 10); // Mengubah string menjadi angka

    // Membentuk format baru "day-MonthName-year"
    return `${day}-${monthNames[month - 1]}-${year}`;
  }

}
