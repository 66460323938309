import { NgModule } from '@angular/core';
import { ChartTrainingCountPerDayComponent } from './chart-training-count-per-day.component';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
    declarations: [ChartTrainingCountPerDayComponent],
    imports: [FuseSharedModule],
    exports: [ChartTrainingCountPerDayComponent],
})
export class ChartTrainingCountPerDayModule {}
