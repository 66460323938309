import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ModulesService } from '../modules/modules.service';
import { Subject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSnackBar } from '@angular/material';
import { tap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { Dashboard } from '../dashboardpool/model/dashboardpool';
import { PaginationPage } from 'app/shared/pagination';
import { Modules } from '../modules/model/modules';
import { DashboardDs } from './model/dashboard-ds';
import { CommonDialog } from 'app/shared/component/dialog/common-dialog/common-dialog';
import { CommonDialogService } from 'app/shared/component/dialog/common-dialog/common-dialog.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    // encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class DashboardComponent implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    types: any[];
    searchTextSubject = new Subject<any>();
    page = new PaginationPage();
    dataSource: DashboardDs;
    selection = new SelectionModel<Dashboard>(true, []);
    searchParam = new Modules();
    category: number;
    id: string;
    emp_id: string;

    constructor(
        private serviceModules: ModulesService,
        public router: Router,
        private splaceScreen: FuseSplashScreenService,
        private route: ActivatedRoute,
        public commonDialogService: CommonDialogService,
        public snackBar: MatSnackBar
    ) {}

    ngOnInit(): void {
        this.route.params.subscribe((observer) => {
            this.id = observer.id;
            this.emp_id = observer.emp_id;
        });

        this.serviceModules.getModulesType().subscribe((response) => {
            this.types = response;
        });

        this.dataSource = new DashboardDs(this.serviceModules,this.router);
        var params ={
            id:this.id,
            emp_id:this.emp_id,
        }
        this.dataSource.loadData(this.page, this.searchParam, params);

        setTimeout(() => {
            // this.splaceScreen.hide();
        }, 3000);
    }

    loadData(): void {
        this.page.offset = this.paginator.pageIndex;
        this.page.limit = this.paginator.pageSize;
        var params ={
            id:this.id,
            emp_id:this.emp_id,
        }
        this.dataSource.loadData(this.page, this.searchParam, params);
        this.selection.clear();
    }

    search(query: string): void {
        this.page.search = query;
        var params ={
            id:this.id,
            emp_id:this.emp_id,
        }
        this.dataSource.loadData(this.page, this.searchParam, params);
    }

    ngAfterViewInit(): void {
        this.paginator.page.pipe(tap(() => this.loadData())).subscribe();
        this.searchTextSubject.subscribe((val) => {
            setTimeout(() => console.log(val), 1000);
        });
    }

    detail(ds: Dashboard): void {
        const p1 = ds.emp_module_pool_id;
        const p2 = ds.el_module_pool_list_id;
        const url = `section_start/${ds.id}/${this.id}/${p1}/${p2}`;
        this.router.navigate([url]);
    }

    submitModuleCheck(data: Modules): void {
        console.log(data);
        const dialog = new CommonDialog({
            title: 'Check Module',
            message: 'Are you sure want to check this item ?',
            isCancelable: true,
            textButtonCancel: 'CANCEL',
        });

        this.commonDialogService.open(dialog).subscribe((val) => {
            if (val.isSubmit()) {
                this.serviceModules.submitModuleCheck(data).subscribe((res) => {
                    this.loadData();
                    this.snackBar.open(res.message, 'OK', {
                        duration: 90000,
                        horizontalPosition: 'end',
                        verticalPosition: 'top',
                        panelClass: ['snackbar-info'],
                    });
                });
            }
            if (val.isCancel() || val.isDestroy()) {
                this.snackBar.open('Request Cancelled', 'OK', {
                    duration: 90000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                    panelClass: ['snackbar-info'],
                });
            }
        });
    }
}
